import React from 'react';
import styles from './EditorOutput.module.scss';

function EditorOutput(props, ref) {
  const [lines, setLines] = React.useState([
    {
      key: 0,
      timestamp: new Date(),
      type: 'info',
      value: 'Editor initialised.',
    },
  ]);
  const consoleRef = React.useRef(null);

  React.useImperativeHandle(ref, () => ({
    push(entry) {
      setLines((currentLines) => [
        ...currentLines,
        {
          key: currentLines.length,
          timestamp: new Date(),
          type: entry.type || 'info',
          value: entry.value,
        },
      ]);
    },
  }));

  React.useEffect(() => {
    if (consoleRef.current) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
    }
  }, [lines]);

  return (
    <div className={styles.console} ref={consoleRef}>
      {lines.map((l) => (
        <div className={styles.line} key={l.key}>
          <div className={styles[l.type]}>
            {`${l.timestamp.toTimeString().substring(0, 9)} - ${l.value}`}
          </div>
        </div>
      ))}
    </div>
  );
}

export default React.forwardRef(EditorOutput);
