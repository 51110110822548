import React from 'react';
import { useSearchParams } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import AuthenticatedRoute from '../../_functional/AuthenticatedRoute';
import APIClient from '../../../util/APIClient';
import Loading from '../Common/Loading/Loading';
import styles from './Leaderboards.module.scss';
import LeaderboardEntry from './LeaderboardEntry/LeaderboardEntry';

function Leaderboards() {
  const [searchParams] = useSearchParams();
  const gameId = searchParams.get('gameId');
  const [leaderboards, setLeaderboards] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const reload = React.useCallback(() => {
    APIClient.get(`/participant/games/${gameId}/leaderboards`)
      .then((result) => {
        setLeaderboards(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [gameId]);

  React.useEffect(() => {
    APIClient.get(`/participant/games/${gameId}/leaderboards`)
      .then((result) => {
        setLeaderboards(result.data);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Failed to fetch source code!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [gameId]);

  if (isLoading) {
    return <Loading />;
  }

  if (leaderboards === null) {
    return (
      <p className={styles.errorBanner}>Failed to load leaderboard entries.</p>
    );
  }

  return (
    <div className={styles.leaderboards}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Leaderboards for game {leaderboards.name}
        </h1>
        <button type="button" onClick={reload}>
          <RefreshIcon htmlColor="#999999" fontSize="inherit" />
        </button>
      </div>
      {leaderboards.maps.map((map) => (
        <LeaderboardEntry key={`map-${map.mapId}`} map={map} />
      ))}
      {(<leaderboards className="maps" />).length === 0 && (
        <div className={styles.noSubmissions}>
          <p>There are no submissions for this game yet.</p>
        </div>
      )}
    </div>
  );
}

export default AuthenticatedRoute(Leaderboards);
