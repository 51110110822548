import React from 'react';
import Select from 'react-select';

const dropdownStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #b01c2e',
    borderLeft: 'none',
    height: '2em',
    minHeight: '2em',
    minWidth: '14em',
    fontFamily: "'Helvetica', 'Arial', sans-serif",
    fontWeight: 300,
    borderRadius: '0 .125em .125em 0',
    boxShadow: 'none',
    zIndex: 10,
    '&:hover': {
      borderColor: '#b01c2e',
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontFamily: "'Helvetica', 'Arial', sans-serif",
    fontWeight: 300,
  }),
  selectContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '.1em .1em .1em .5em',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 300,
    color: '#666666',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    zIndex: 210,
    borderRadius: '0 0 .125em .125em',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 .25em 0 .125em',
    color: '#b01c2e',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    fontFamily: "'Helvetica', 'Arial', sans-serif",
    fontWeight: 300,
    fontSize: '.85em',
    backgroundColor: isFocused ? '#b01c2e' : '#ffffff',
    color: isFocused ? '#ffffff' : '#666666',
    '&:active': {
      backgroundColor: '#f8dddd',
      color: '#b01c2e',
    },
  }),
};

export default function Dropdown(props) {
  return <Select {...props} styles={dropdownStyles} />;
}
