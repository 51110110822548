import React from 'react';
import styles from './Loading.module.scss';
import loadingGif from '../../../../assets/images/airship.gif';

const DEFAULT_MESSAGE = 'Loading...';

export default function Loading(props) {
  const { message } = props;

  return (
    <div className={styles.loading}>
      <img src={loadingGif} alt="Loading..." />
      <p>{message || DEFAULT_MESSAGE}</p>
    </div>
  );
}
