import React from 'react';
import { Link } from 'react-router-dom';
import styles from './MapTile.module.scss';
import placeholder from '../../../../assets/icons/placeholder.svg';
import map1Thumb from '../../../../assets/images/map_thumbnails/map1.png';
import map2Thumb from '../../../../assets/images/map_thumbnails/map2.png';
import map3Thumb from '../../../../assets/images/map_thumbnails/map3.png';
import map4Thumb from '../../../../assets/images/map_thumbnails/map4.png';
import map5Thumb from '../../../../assets/images/map_thumbnails/map5.png';
import map6Thumb from '../../../../assets/images/map_thumbnails/map6.png';
import map7Thumb from '../../../../assets/images/map_thumbnails/map7.png';
import map8Thumb from '../../../../assets/images/map_thumbnails/map8.png';

const mapThumbinals = {
  map1: map1Thumb,
  map2: map2Thumb,
  map3: map3Thumb,
  map4: map4Thumb,
  map5: map5Thumb,
  map6: map6Thumb,
  map7: map7Thumb,
  map8: map8Thumb,
};

export default function MapTile(props) {
  const {
    map: { id, configId, name, difficulty },
  } = props;

  return (
    <Link to={`/sourceCode/new?mapId=${id}&configId=${configId}`}>
      <div className={styles.mapName}>
        <img
          alt={`Map thumbnail for ${name}`}
          src={
            Object.keys(mapThumbinals).includes(configId)
              ? mapThumbinals[configId]
              : placeholder
          }
        />
        <h2>{name}</h2>
        <p>
          Difficulty: <span className={styles.difficulty}>{difficulty}</span>{' '}
        </p>
      </div>
    </Link>
  );
}
