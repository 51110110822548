import React from 'react';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import styles from './NavbarLogo.module.scss';
import uobLogo from '../../../../../assets/images/uob_logo.svg';
import uobLogoThumb from '../../../../../assets/images/uob_logo_thumb.svg';

export default function NavbarLogo(props) {
  const { handleToggleClick, isExpanded } = props;

  function onToggleClick() {
    handleToggleClick();
  }

  return (
    <div
      className={`${styles.navbarLogo} ${isExpanded ? styles.expanded : ''}`}
    >
      <img
        src={(isExpanded && uobLogo) || uobLogoThumb}
        alt="University of Bristol logo"
      />
      <button type="button" onClick={onToggleClick}>
        <ArrowCircleRightOutlinedIcon fontSize="inherit" htmlColor="#999" />
      </button>
    </div>
  );
}
