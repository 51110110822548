const simulatorConfig = require('@airship/simulator-config');

class Utilities {
  /**
   * Return a map configuration for a vehicle with specified ID.
   * @param configId ID of the map to be returned.
   * @returns {{difficulty: string, goal: {position: number[], radius: number}, name: string, model: {filename: string, readonly file: *}, geometry: [{rotation: number[], position: number[], nodeId: string},{rotation: number[], position: number[], nodeId: string},{rotation: number[], position: number[], nodeId: string},{rotation: number[], position: number[], nodeId: string},{rotation: number[], position: number[], nodeId: string}], id: string, vehicle: {rotation: number[], vehicleId: string, position: number[]}}|{difficulty: string, goal: {position: number[], radius: number}, name: string, model: {filename: string, readonly file: *}, geometry, id: string, vehicle: {rotation: number[], vehicleId: string, position: number[]}}|{difficulty: string, goal: {position: number[], radius: number}, name: string, model: {filename: string, readonly file: *}, geometry, id: string, vehicle: {rotation: number[], vehicleId: string, position: number[]}}|{difficulty: string, goal: {position: number[], radius: number}, name: string, model: {filename: string, readonly file: *}, geometry, id: string, vehicle: {rotation: number[], vehicleId: string, position: number[]}}} Map configuration
   */
  static getMapConfigByConfigId(configId) {
    const filteredMaps = simulatorConfig.maps.filter(
      (map) => map.id === configId
    );
    if (filteredMaps.length !== 1) {
      throw new Error(
        `Invalid Map ID provided! Map with config ID "${configId}" does not exist!`
      );
    }
    return filteredMaps[0];
  }

  /**
   * Return a vehicle configuration for a vehicle with specified ID.
   * @param vehicleId ID of the vehicle to be returned.
   * @returns {{model: {filename: string, readonly file: *}, id: string, nodeId: string}|{model: {filename: string, readonly file: *}, id: string, nodeId: string}} Vehicle configuration.
   */
  static getVehicleConfigById(vehicleId) {
    const filteredVehicles = simulatorConfig.vehicles.filter(
      (vehicle) => vehicle.id === vehicleId
    );
    if (filteredVehicles.length !== 1) {
      throw new Error(
        `Invalid Vehicle ID provided! Vehicle with id "${vehicleId}" does not exist!`
      );
    }
    return filteredVehicles[0];
  }

  /**
   * Compute the center position and scale of a box defined by the supplied `a` and `b` points.
   * @param a First point
   * @param b Second point
   * @returns {{posX: number, posY: number, posZ: number, scaX: number, scaZ: number, scaY: number}}
   */
  static getBoxPropertiesFromCorners([a, b]) {
    const posX = Math.min(a[0], b[0]) + Math.abs(a[0] - b[0]) / 2;
    const posY = Math.min(a[1], b[1]) + Math.abs(a[1] - b[1]) / 2;
    const posZ = Math.min(a[2], b[2]) + Math.abs(a[2] - b[2]) / 2;
    const scaX = Math.abs(a[0] - b[0]);
    const scaY = Math.abs(a[1] - b[1]);
    const scaZ = Math.abs(a[2] - b[2]);
    return { posX, posY, posZ, scaX, scaY, scaZ };
  }

  /**
   * Return a model matching specified nodeId from loaded GLTF scene's children.
   * @param gltf Instance of loaded GLTF model.
   * @param nodeId String representing the ID of the node to return.
   * @returns {THREE.Object3D} Object3D corresponding to the child with matching name property in GLTF's scene.
   */
  static getModelByNodeId(gltf, nodeId) {
    const matchingModels = gltf.scene.children.filter(
      (model) => model.userData.name === nodeId
    );
    if (matchingModels.length !== 1) {
      throw new Error(
        'Node with specified ID could not be found in the provided GLTF.'
      );
    }
    return matchingModels[0].clone();
  }
}

module.exports = Utilities;
