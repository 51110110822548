import React from 'react';
import { Auth } from 'aws-amplify';
import AuthenticationContext from '../../../../contexts/AuthenticationContext';
import AuthenticatedRoute from '../../../_functional/AuthenticatedRoute';
import AccountFormContext from '../../../../contexts/AccountFormContext';

function ChangePassword() {
  const { setMessage, setMessageType } = React.useContext(AccountFormContext);
  const { currentUser } = React.useContext(AuthenticationContext);
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [repeatNewPassword, setRepeatNewPassword] = React.useState('');

  async function onSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await Auth.changePassword(
        currentUser,
        currentPassword,
        repeatNewPassword
      );
      setMessage('Password has been updated.');
      setMessageType('success');
    } catch (error) {
      setMessage('Could not update password.');
      setMessageType('error');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <h1>Change password</h1>
      <form onSubmit={onSubmit}>
        <label htmlFor="currentPassword">
          Current password:
          <input
            type="password"
            name="password"
            autoComplete="current-password"
            value={currentPassword}
            onChange={(e) => {
              e.preventDefault();
              setCurrentPassword(e.target.value);
            }}
          />
        </label>
        <label htmlFor="newPassword">
          New password:
          <input
            type="password"
            name="password"
            autoComplete="off"
            value={newPassword}
            onChange={(e) => {
              e.preventDefault();
              setNewPassword(e.target.value);
            }}
          />
        </label>
        <label htmlFor="repeatNewPassword">
          Repeat new password:
          <input
            type="password"
            name="repeatNewPassword"
            autoComplete="off"
            value={repeatNewPassword}
            onChange={(e) => {
              e.preventDefault();
              setRepeatNewPassword(e.target.value);
            }}
          />
        </label>
        <input type="submit" value="Change password" disabled={isSubmitting} />
      </form>
    </>
  );
}

export default AuthenticatedRoute(ChangePassword);
