import React, { Suspense } from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { Simulator } from '@airship/simulator';
import Utilities from '@airship/simulator/src/Utilities';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import ConstructionIcon from '@mui/icons-material/Construction';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import Editor from './Editor/Editor';
import styles from './SourceCode.module.scss';
import Viewport from './Viewport/Viewport';
import EditorOutput from './EditorOutput/EditorOutput';
import Loading from '../../Common/Loading/Loading';
import useSimulator from '../../../../hooks/useSimulator';
import APIClient from '../../../../util/APIClient';
import Dropdown from '../../Common/Dropdown/Dropdown';

const MAX_SOURCE_CODE_NAME_LENGTH = 50;
const DEFAULT_SOURCE_CODE_NAME = 'My code';
const DEFAULT_CODE = `function onStart(vehicle: Vehicle) {
  // This code only runs once when the simulation starts.
  vehicle.setThrottle(1);
}

function onUpdate(vehicle: Vehicle) {
  // This code runs on every update.
}
`;

const DEFAULT_CODE_COMPILED = `function onStart(vehicle) {
  // This code only runs once when the simulation starts.
  vehicle.setThrottle(1);
}
function onUpdate(vehicle) {
  // This code runs on every update.
}
`;

function showSimulatorStatusMessages(simulator, printingFunction) {
  const reachedGoal = simulator.hasReachedGoal();
  const crashed = simulator.hasVehicleCrashed();
  const thrownError = simulator.hasUserCodeThrownError();
  const timedOut = simulator.hasSimulationTimedOut();

  if (reachedGoal) {
    printingFunction({
      type: 'success',
      value: 'Goal was reached.',
    });
    printingFunction({
      type: 'success',
      value: `Your provisional score is: ${simulator.getScore()}.`,
    });
  } else if (crashed || thrownError || timedOut) {
    if (crashed) {
      printingFunction({
        type: 'error',
        value: 'Vehicle crashed!',
      });
    } else if (thrownError) {
      printingFunction({
        type: 'error',
        value: 'User code threw an error!',
      });
    } else if (timedOut) {
      printingFunction({
        type: 'error',
        value: 'Vehicle did not reach the goal in time, simulation timed out.',
      });
    }
    printingFunction({
      type: 'error',
      value: `Your provisional score is: ${simulator.getScore()}.`,
    });
  }
}

export default function SourceCode() {
  const [searchParams] = useSearchParams();
  const { sourceCodeId } = useParams();
  const navigate = useNavigate();

  const mapId = searchParams.get('mapId');
  const configId = searchParams.get('configId');
  const [sourceCodeName, setSourceCodeName] = React.useState(
    DEFAULT_SOURCE_CODE_NAME
  );
  const [isEditingSourceCodeName, setIsEditingSourceCodeName] =
    React.useState(false);
  const [codeChanged, setCodeChanged] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [code, setCode] = React.useState(DEFAULT_CODE);
  const [compiledCode, setCompiledCode] = React.useState(DEFAULT_CODE_COMPILED);
  const editorRef = React.useRef({});
  const editorOutputRef = React.useRef('');
  const [colliderMeshes, setColliderMeshes] = React.useState([]);
  const sourceCodeNameInputRef = React.useRef(null);

  const mapConfig = Utilities.getMapConfigByConfigId(configId);
  const vehicleConfig = Utilities.getVehicleConfigById(
    mapConfig.vehicle.vehicleId
  );

  const [isLoadingSourceCode, setIsLoadingSourceCode] = React.useState(true);
  const [isLoadingGames, setIsLoadingGames] = React.useState(true);
  const [gameOptions, setGameOptions] = React.useState([]);
  const [selectedGame, setSelectedGame] = React.useState(null);
  const [isDebugModeEnabled, setIsDebugModeEnabled] = React.useState(false);
  const [simulator, setSimulator] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [mapGLTF, setMapGLTF] = React.useState(null);
  const [vehicleGLTF, setVehicleGLTF] = React.useState(null);
  const { frames, setFrames } = useSimulator();
  const viewportRef = React.useRef(null);

  function updateSourceCodeName(e) {
    e.preventDefault();
    setSourceCodeName(e.target.value.substr(0, MAX_SOURCE_CODE_NAME_LENGTH));
  }

  function editSourceCodeName(e) {
    e.preventDefault();
    setIsEditingSourceCodeName((current) => !current);
  }

  function pushToEditorOutput(value) {
    editorOutputRef.current.push(value);
  }

  function setCurrentFrame(frameNumber) {
    viewportRef.current.setCurrentFrame(frameNumber);
  }

  async function build() {
    pushToEditorOutput({ type: 'info', value: 'Building code.' });
    try {
      const newCompiledCode = await editorRef.current.compileTsToJs();
      simulator.updateFunctionCode(newCompiledCode);
      setCodeChanged(false);
      setCompiledCode(newCompiledCode);
      pushToEditorOutput({ type: 'info', value: 'Code built successfully.' });
    } catch (error) {
      pushToEditorOutput({ type: 'error', value: error.message });
    }
  }

  function save() {
    APIClient.patch(`/participant/sourceCode/${sourceCodeId}`, {
      name: sourceCodeName,
      code,
      compiledCode,
    })
      .then(() => {
        // eslint-disable-next-line no-alert
        alert('Source code saved!');
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Failed to save source code!');
      });
  }

  function submitToGame() {
    setIsSubmitting(true);
    APIClient.patch(`/participant/sourceCode/${sourceCodeId}`, {
      name: sourceCodeName,
      code,
      compiledCode,
    })
      .then(() => {
        APIClient.post(`/participant/sourceCode/${sourceCodeId}/submissions`, {
          gameId: selectedGame.value,
          mapId,
        })
          .then(() => {
            // eslint-disable-next-line no-alert
            alert('Source code submitted successfully!');
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              // eslint-disable-next-line no-alert
              alert(
                'Failed to submit the source code to game! You cannot submit code with this map.'
              );
            } else if (error.response && error.response.status === 423) {
              // eslint-disable-next-line no-alert
              alert(
                'Failed to submit the source code to game! The game does not accept new submissions.'
              );
            } else {
              // eslint-disable-next-line no-alert
              alert('Failed to submit the source code to game!');
            }
          });
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Failed to save source code while submitting to a game!');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  function updateEditorCode(value) {
    setCode(value);
    setCodeChanged(true);
  }

  function rewindToStart() {
    pushToEditorOutput({ type: 'info', value: 'Rewinding to start.' });
    setCurrentFrame(0);
  }

  function rewindToEnd() {
    pushToEditorOutput({ type: 'info', value: 'Rewinding to end.' });
    setCurrentFrame(frames.length - 1);
  }

  function stepForward() {
    pushToEditorOutput({ type: 'info', value: `Stepping forward.` });
    viewportRef.current.stepForward();
  }

  function stepBack() {
    pushToEditorOutput({ type: 'info', value: `Stepping back.` });
    viewportRef.current.stepBack();
  }

  function play() {
    viewportRef.current.play();
    pushToEditorOutput({ type: 'info', value: `Starting playback.` });
  }

  function pause() {
    viewportRef.current.pause();
    pushToEditorOutput({ type: 'info', value: `Pausing playback.` });
  }

  function stop() {
    pause();
    rewindToStart();
    pushToEditorOutput({ type: 'info', value: `Stopping playback.` });
  }

  function runSimulation() {
    pushToEditorOutput({ type: 'info', value: `Running simulation.` });
    // Set timeout to allow the editor output to appear before compute-costly simulation begins.
    setTimeout(() => {
      try {
        simulator.reset();
        simulator.simulateUntilDone();
        setFrames(simulator.getFrames());
        rewindToStart();
        play();
      } catch (error) {
        pushToEditorOutput({ type: 'error', value: error.message });
      }
      showSimulatorStatusMessages(simulator, pushToEditorOutput);
      setFrames(simulator.getFrames());
    }, 100);
  }

  function toggleDebugMode() {
    setIsDebugModeEnabled((current) => !current);
  }

  React.useEffect(() => {
    if (sourceCodeId === 'new') {
      APIClient.post(`/participant/sourceCode`, {
        name: DEFAULT_SOURCE_CODE_NAME,
        code: DEFAULT_CODE,
        compiledCode: DEFAULT_CODE_COMPILED,
      })
        .then((result) => {
          navigate(
            `/sourceCode/${result.data.id}?mapId=${mapId}&configId=${configId}`
          );
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('Failed to create new source code!');
        })
        .finally(() => {
          setIsLoadingSourceCode(false);
        });
    } else {
      APIClient.get(`/participant/sourceCode/${sourceCodeId}`)
        .then((result) => {
          setSourceCodeName(result.data.name);
          setCode(result.data.code);
          setCompiledCode(result.data.compiledCode);
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('Failed to load source code!');
        })
        .finally(() => {
          setIsLoadingSourceCode(false);
        });
      APIClient.get(`/participant/games?includeMaps=true`)
        .then((result) => {
          setGameOptions(
            result.data
              .map((game) => ({
                value: game.id,
                label: game.name,
                isDisabled: !game.maps.map((m) => m.id).includes(mapId),
              }))
              .map((option) => ({
                ...option,
                label: `${option.label}${
                  option.isDisabled ? ' (wrong map)' : ''
                }`,
              }))
          );
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('Failed to load games!');
        })
        .finally(() => {
          setIsLoadingGames(false);
        });
    }
  }, [mapId, configId, navigate, sourceCodeId]);

  React.useEffect(() => {
    setSelectedGame(
      gameOptions.some((o) => o && !o.isDisabled) ? gameOptions[0] : null
    );
  }, [gameOptions]);

  React.useEffect(() => {
    if (isEditingSourceCodeName === true) {
      sourceCodeNameInputRef.current.setSelectionRange(
        sourceCodeName.length,
        sourceCodeName.length
      );
      sourceCodeNameInputRef.current.focus();
    }
  }, [sourceCodeName, isEditingSourceCodeName]);

  React.useEffect(() => {
    const loader = new GLTFLoader();
    loader.load(mapConfig.model.file.default, (gltf) => {
      setMapGLTF(gltf);
    });
    loader.load(vehicleConfig.model.file.default, (gltf) => {
      setVehicleGLTF(gltf);
    });
  }, [mapConfig, vehicleConfig]);

  React.useEffect(() => {
    if (!!mapGLTF && !!vehicleGLTF) {
      setSimulator(
        () => new Simulator(mapConfig, vehicleConfig, mapGLTF, vehicleGLTF)
      );
    }
  }, [mapConfig, mapGLTF, vehicleConfig, vehicleGLTF]);

  React.useEffect(() => {
    if (simulator) {
      setFrames(simulator.getFrames());
    }
  }, [setFrames, simulator]);

  React.useEffect(() => {
    if (isDebugModeEnabled) {
      setColliderMeshes(simulator.getColliderMeshes());
    } else {
      setColliderMeshes([]);
    }
  }, [isDebugModeEnabled, simulator]);

  if (isLoadingSourceCode || isLoadingGames) {
    return <Loading />;
  }

  return (
    <div className={styles.sourceCode}>
      <Suspense
        fallback={<Loading message="Loading simulation environment..." />}
      >
        <div className={styles.topBar}>
          <form className={styles.name} onSubmit={editSourceCodeName}>
            <input
              type="text"
              style={{
                width: `${sourceCodeName.length * 0.6 + 1}em`,
                minWidth: sourceCodeName.length > 0 ? '0' : '17.8em',
              }}
              placeholder={"Enter your source code's name"}
              className={styles.name}
              value={sourceCodeName}
              onChange={(e) => updateSourceCodeName(e)}
              disabled={!isEditingSourceCodeName}
              ref={sourceCodeNameInputRef}
            />
            <button type="button" onClick={editSourceCodeName}>
              <EditIcon htmlColor="#666666" fontSize="inherit" />
            </button>
          </form>
          <div className={styles.submitToGame}>
            <button
              type="button"
              onClick={submitToGame}
              disabled={gameOptions.length === 0 || isSubmitting}
            >
              Submit to game
            </button>
            <Dropdown
              options={gameOptions}
              defaultValue={
                gameOptions.some((o) => o && !o.isDisabled)
                  ? gameOptions[0]
                  : null
              }
              isSearchable={false}
              placeholder="select game..."
              noOptionsMessage={() => 'No games available'}
              onChange={(option) => setSelectedGame(option)}
            />
          </div>
        </div>
        <div className={styles.ide}>
          <div className={styles.left}>
            <div className={styles.viewportControlsWrapper}>
              <div className={styles.viewportControls}>
                <div className={styles.playbackControls}>
                  <button
                    type="button"
                    onClick={rewindToStart}
                    disabled={codeChanged}
                  >
                    <FirstPageIcon htmlColor="#666666" fontSize="inherit" />
                  </button>
                  <button
                    type="button"
                    onClick={stepBack}
                    disabled={codeChanged}
                  >
                    <ChevronLeftIcon htmlColor="#666666" fontSize="inherit" />
                  </button>
                  <button type="button" onClick={play} disabled={codeChanged}>
                    <PlayArrowIcon htmlColor="#666666" fontSize="inherit" />
                  </button>
                  <button type="button" onClick={pause} disabled={codeChanged}>
                    <PauseIcon htmlColor="#666666" fontSize="inherit" />
                  </button>
                  <button type="button" onClick={stop} disabled={codeChanged}>
                    <StopIcon htmlColor="#666666" fontSize="inherit" />
                  </button>
                  <button
                    type="button"
                    onClick={stepForward}
                    disabled={codeChanged}
                  >
                    <ChevronRightIcon htmlColor="#666666" fontSize="inherit" />
                  </button>
                  <button
                    type="button"
                    onClick={rewindToEnd}
                    disabled={codeChanged}
                  >
                    <LastPageIcon htmlColor="#666666" fontSize="inherit" />
                  </button>
                </div>
                <div className={styles.spacer} />
                <div className={styles.playbackControls}>
                  <button type="button" onClick={toggleDebugMode}>
                    <BugReportOutlinedIcon
                      htmlColor="#666666"
                      fontSize="inherit"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.viewport}>
              <Viewport
                colliderMeshes={colliderMeshes}
                configId={configId}
                ref={viewportRef}
              />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.editorControlsWrapper}>
              <div className={styles.editorControls}>
                <button
                  type="button"
                  onClick={runSimulation}
                  disabled={codeChanged}
                >
                  <ArrowCircleRightIcon
                    htmlColor="#666666"
                    fontSize="inherit"
                  />
                  <span>Simulate</span>
                </button>
              </div>
              <div className={styles.horizontalFill} />
              <div className={styles.editorControls}>
                <button
                  className={styles.buildButton}
                  type="button"
                  onClick={build}
                  disabled={!codeChanged}
                >
                  <ConstructionIcon
                    htmlColor={codeChanged ? '#b01c2e' : '#666666'}
                    fontSize="inherit"
                  />
                  <span>Build</span>
                </button>
                <button type="button" onClick={save}>
                  <SaveOutlinedIcon htmlColor="#666666" fontSize="inherit" />
                  <span>Save</span>
                </button>
              </div>
            </div>
            <div className={styles.editor}>
              <Editor
                onChange={(value) => updateEditorCode(value)}
                code={code}
                externalRef={editorRef}
              />
            </div>
          </div>
        </div>
        <div className={styles.editorOutput}>
          <EditorOutput ref={editorOutputRef} />
        </div>
      </Suspense>
    </div>
  );
}
