import React from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Link } from 'react-router-dom';
import { Utilities } from '@airship/simulator';
import Dropdown from '../../../Common/Dropdown/Dropdown';
import styles from './SourceCodeListEntry.module.scss';
import APIClient from '../../../../../util/APIClient';

export default function SourceCodeListEntry(props) {
  const { id, name, code, compiledCode, submissions, maps, reload } = props;
  const mapOptions = maps.map((m) => ({
    value: m.id,
    label: `${m.name} (${m.difficulty})`,
  }));
  const defaultSelectedMap =
    mapOptions.length > 0
      ? maps.filter((map) => map.id === mapOptions[0].value)[0]
      : null;
  const [selectedMap, setSelectedMap] = React.useState(defaultSelectedMap);

  const onMapChange = React.useCallback(
    (option) => {
      const matchingMaps = maps.filter((map) => map.id === option.value);
      if (matchingMaps.length !== 1) {
        throw new Error('Invalid map selected!');
      }
      setSelectedMap(matchingMaps[0]);
    },
    [maps]
  );

  async function deleteSourceCode(e) {
    e.preventDefault();
    try {
      await APIClient.delete(`/participant/sourceCode/${id}`);
      reload();
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Failed to delete the source code!');
    }
  }

  async function duplicateSourceCode(e) {
    e.preventDefault();
    try {
      await APIClient.post('/participant/sourceCode', {
        name: `Copy of ${name}`,
        code,
        compiledCode,
      });
      reload();
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Failed to create copy of the code!');
    }
  }

  return (
    <div className={styles.sourceCodeListEntry}>
      <div className={styles.sourceCodeHeader}>
        <p className={styles.name}>{name}</p>
        <div className={styles.horizontalFill} />
        <Link
          className={styles.link}
          to={
            selectedMap
              ? `/sourceCode/${id}?mapId=${selectedMap.id}&configId=${selectedMap.configId}`
              : ''
          }
        >
          Test on
        </Link>
        <Dropdown
          isSearchable={false}
          options={mapOptions}
          onChange={onMapChange}
          defaultValue={mapOptions.length > 0 ? mapOptions[0] : null}
          placeholder="select map..."
          noOptionsMessage={() => 'No maps available'}
        />
        <button type="button" onClick={duplicateSourceCode}>
          <ContentCopyOutlinedIcon htmlColor="#999999" fontSize="inherit" />
        </button>
        <button type="button" onClick={deleteSourceCode}>
          <DeleteOutlinedIcon htmlColor="#999999" fontSize="inherit" />
        </button>
      </div>
      <div className={styles.sourceCodeSubmissions}>
        {Array.isArray(submissions) &&
          submissions.length > 0 &&
          submissions.map((submission) => {
            const matchingMaps = maps.filter(
              (map) => map.id === submission.mapId
            );
            if (matchingMaps.length !== 1) {
              throw new Error('Invalid map id present in submission!');
            }
            const mapConfig = Utilities.getMapConfigByConfigId(
              matchingMaps[0].configId
            );
            const mapName = mapConfig.name;

            return (
              <div className={styles.submission} key={submission.id}>
                <p className={styles.mapName}>{mapName}</p>
                <p className={styles.score}>{submission.status || ''}</p>
              </div>
            );
          })}
        {Array.isArray(submissions) && submissions.length === 0 && (
          <p className={styles.noSubmissions}>
            This code has not been submitted to any games yet!
          </p>
        )}
      </div>
    </div>
  );
}
