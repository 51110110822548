const Game = require('./Game');
const UserCode = require('./UserCode');

class Simulator {
  constructor(mapConfig, vehicleConfig, mapGLTF, vehicleGLTF) {
    this.mapConfig = mapConfig;
    this.vehicleConfig = vehicleConfig;
    this.mapGLTF = mapGLTF;
    this.vehicleGLTF = vehicleGLTF;
    this.onUpdate = null;
    this.onStart = null;
    this.reset();
  }

  _ensureUserFunctions() {
    if (typeof this.onStart !== 'function') {
      throw new Error('The onStart function needs to be defined!');
    }
    if (typeof this.onUpdate !== 'function') {
      throw new Error('The onUpdate function needs to be defined!');
    }
  }

  getColliderMeshes() {
    return this.game.physics.rigidBodies;
  }

  updateFunctionCode(code) {
    const userCode = new UserCode(code);
    try {
      this.onUpdate = userCode.getOnUpdateFunction();
      this.onStart = userCode.getOnStartFunction();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Failed to build user function.');
      // Failed to build user functions.
      throw new Error(error);
    }
  }

  update() {
    this._ensureUserFunctions();
    const vehicleAPI = this.game.vehicle.getApi();

    try {
      if (this.game.frames.length === 1) {
        this.onStart(vehicleAPI);
      } else {
        this.onUpdate(vehicleAPI);
      }
    } catch (error) {
      // User function threw an error.
      throw new Error(UserCode._wrapUserCodeError(error));
    }
  }

  reset() {
    this.game = new Game(
      this.mapConfig,
      this.vehicleConfig,
      this.mapGLTF,
      this.vehicleGLTF
    );
  }

  simulateUntilDone() {
    while (!this.game._isSimulationDone()) {
      this.update();
      this.game.simulateStep();
    }
  }

  getFrames() {
    return this.game.getFrames();
  }

  getScore() {
    return this.game.getScore();
  }

  hasReachedGoal() {
    return this.game.goalReached;
  }

  hasVehicleCrashed() {
    return this.game.vehicleCrashed;
  }

  hasUserCodeThrownError() {
    return this.game.userCodeHasThrown;
  }

  hasSimulationStarted() {
    return this.game.getFrames().length !== 0;
  }

  hasSimulationTimedOut() {
    return this.game.getFrames().length === this.game.maxFrameCount;
  }
}

module.exports = Simulator;
