import React from 'react';
import MonacoEditor from '@monaco-editor/react';

const LIB_URI = 'ts:filename/airship.d.ts';
const LIB_SOURCE = `type Sensor = {
  /**
   * The name of the sensor.
   */
  name: string;

  /**
   * Indicator of whether the sensor has detected an obstacle within its range. This value will be \`true\` if the sensor has encountered an obstacle, or \`false\` if there was not anything within its range.
   */
  hit: boolean;

  /**
   * The distance to the closest obstacle. This value will be equivalent to the sensor's maximum ranging distance if there was not an obstacle within range.
   */
  distance: number;
}

/**
 * You can control your vehicle primarily by setting its throttle (otherwise known as the propeller speed) and rudder (that's the control surface at the back of the airship that turns if left and right). In addition to that, you have a wide range of telemetry, or readings about the vehicle status, such as its altitude, GPS position, and proximity sensor readings. The below sections will explain how you can access and use both the control commands and the telemetry of the airship.
 *
 * Your vehicle can go backwards and forwards. It can also turn left and right. It's relatively simple! You can control the speed at which you move forward (or back) using the \`setThrottle\` function, and the rotation of your airship with \`setRudder\` function.
 * To direct your vehicle towards the goal, you will have to use both the knowledge of the map, and the access to a variety of vehicle sensors. You can approach this task by first looking at the map you wish to complete, and then thinking of a strategy to navigate through it.
 *
 * There are three main approaches to directing your airship. You can either design a path manually, by specifying when to set the rudder left, or right depending on the gps coordinates and the on-board compass. If this seems like a challenging process, don't worry! There are smarter way of providing directions to your airship. A good approach would be to make use of vehicle's proximity sensors, which will inform you about the distance to the obstacles in the direction of each sensor.
 *
 * Alternatively, you could combine both approaches and guide the airship through the narrowest of passages by allowing it to rely on sensors between given sets of coordinates, and forcing it to fly a certain compass heading between another.
 */
declare class Vehicle {

  /**
   * The above-sea-level and above-ground altitudes of the airship.
   */
  altitude: {
    /**
     * The altitude of the airship measured over the constant sea-level.
     */
    seaLevel: number;

    /**
     * The distance to the ground directly below the airship.
     */
    ground: number;
  };

  /**
   * Compass contains the orientation of the airship on the XZ-plane.
   */
  compass: {
    /**
     * The heading of the airship in the range [0: 360). The value of 180 corresponds to facing the x-axis directly, and the value of 90 corresponds to facing z-axis directly.
     */
    heading: number,
  };

  /**
   * The speed of the airship.
   */
  speed: number;

  /**
   * The velocity of the airship. As compared to speed (which is a scalar value), velocity is a vector and contains directional information about how fast the airship is moving.
   */
  velocity: {
    /**
     * The x-component of the velocity.
     */
    x: number;
    /**
     * The y-component of the velocity.
     */
    y: number;
    /**
     * The z-component of the velocity.
     */
    z: number;
  }

  /**
   * The direction the airship is facing. Direction is the vector equivalent of the compass heading; the airship does not necessarily move in the direction it is facing, due to its drag and angular velocity.
   */
  direction: {
    /**
     * The x-component of the direction.
     */
    x: number;
    /**
     * The y-component of the direction.
     */
    y: number;
    /**
     * The z-component of the direction.
     */
    z: number;
  }

  /**
   * The direction of drag that the airship is experiencing.
   */
  drag: {
    /**
     * The x-component of the drag.
     */
    x: number;
    /**
     * The y-component of the drag.
     */
    y: number;
    /**
     * The z-component of the drag.
     */
    z: number;
  };

  /**
   * The array of the proximity sensors that the airship is equipped with. The available sensors have the following \`id\`s:
   * - left
   * - frontLeft
   * - front
   * - frontRight
   * - right
   */
  sensors: Sensor[];


  /**
   * Set the propeller speed of the airship to go forward and back.
   *
   * @param value - The direction and magnitude of the propeller rotation. Setting this value to -1 will 'pull' the airship backwards, and setting it to 1 will 'push' the airship forwards. By setting the values in-between, the vehicle will move slower in either direction.
   */
  setThrottle(value: number): void;

  /**
   * Set the rudder of the airship to turn left or right.
   *
   * @param value - The direction and magnitude of the rudder deviation. Setting this value to 1 will turn the airship left, and setting it to -1 will turn it to the right. By setting the values in-between, the vehicle will turn slower in either direction.
   */
  setRudder(value: number): void;
  
}
`;
export default function Editor(props) {
  const { onChange, code, externalRef } = props;
  const editorRef = React.useRef(null);
  const monacoRef = React.useRef(null);

  async function compileTsToJs() {
    const model = monacoRef.current.editor
      .getModels()
      // eslint-disable-next-line no-underscore-dangle
      .filter((m) => m._associatedResource._formatted !== LIB_URI)[0];

    const worker =
      await monacoRef.current.languages.typescript.getTypeScriptWorker();
    const proxy = await worker(model.uri);
    const { outputFiles } = await proxy.getEmitOutput(model.uri.toString());
    return outputFiles[0].text;
  }

  React.useImperativeHandle(
    externalRef,
    () => ({
      compileTsToJs,
    }),
    []
  );

  const onEditorDidMount = React.useCallback(
    (editorInstance, monacoInstance) => {
      editorRef.current = editorInstance;
      monacoRef.current = monacoInstance;
      editorRef.current.updateOptions({ minimap: { enabled: false } });

      monacoRef.current.languages.typescript.javascriptDefaults.setDiagnosticsOptions(
        {
          noSemanticValidation: true,
          noSyntaxValidation: false,
        }
      );

      // compiler options
      monacoRef.current.languages.typescript.javascriptDefaults.setCompilerOptions(
        {
          target: monacoRef.current.languages.typescript.ScriptTarget.ES2015,
          allowNonTsExtensions: true,
        }
      );

      // Create model unless it already exists
      const existingModel = monacoRef.current.editor.getModel(LIB_URI);

      if (!existingModel) {
        monacoRef.current.languages.typescript.javascriptDefaults.addExtraLib(
          LIB_SOURCE,
          LIB_URI
        );
        monacoRef.current.editor.createModel(
          LIB_SOURCE,
          'typescript',
          monacoRef.current.Uri.parse(LIB_URI)
        );
      }
    },
    []
  );

  return (
    <MonacoEditor
      height="100%"
      language="typescript"
      defaultValue={code}
      onChange={onChange}
      onMount={onEditorDidMount}
    />
  );
}
