/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import styles from '../Documentation.module.scss';

export default function JavascriptBasics() {
  return (
    <>
      <h1>JavaScript Basics</h1>
      <div className={styles.section}>
        <p>
          JavaScript was initially created to “make web pages alive”. In this
          game you will use JavaScript to control an air ship and navigate it
          through the world.
        </p>
      </div>
      <div className={styles.section}>
        <h2>Variables</h2>
        <p>
          An important aspect of programming is variables. These allow you to
          store and label data. In JavaScript you can create variables with let:
        </p>
        <code>let myVariable;</code>
        <br />
        <p>
          You can name a variable nearly anything, but there are some
          restrictions. (For example it cannot start with a number and can only
          contain numbers, letter, _ and $). It is worth noting variable names
          are case sensitive. So myVariable is not the same as myvariable.
        </p>
        <br />
        <p>After declaring a variable, you can give it a value:</p>

        <code>myVariable = 1;</code>
        <br />
        <p>You can do both these operations on the same line:</p>

        <code>myVariable = 1;</code>
        <br />

        <p>You retrieve the value by calling the variable name:</p>

        <code>myVariable;</code>
        <br />
        <p>
          After assigning a value to a variable, you can change it later in the
          code:
        </p>
        <br />
        <code>
          {`let a = 10;
             a = 12;`}
        </code>
      </div>
      <div className={styles.section}>
        <h2>Operators</h2>

        <p>
          An operator is a mathematical symbol that produces a result based on
          two values (or variables). In the following table, you can see some of
          the simplest operators, along with some examples to try in the
          JavaScript console.
        </p>

        <p>= Assigns a value to a variable</p>
        <code>{`let hello = 'World';`}</code>
        <br />
        <p>=== Checks if 2 variables are equal</p>
        <br />
        <code>count === 4;</code>
        <br />
        <p>!== Checks if 2 variables are no equal</p>
        <code>count !== 4;</code>
        <br />
        <p>+,-,*,/ Addition, Subtraction, Multiplication, Division</p>
        <code>1 * 2;</code>
      </div>
      <div className={styles.section}>
        <h2>Conditionals</h2>

        <p>
          Conditionals are code structures used to test if an expression returns
          true or not. A very common form of conditionals is the if ... else
          statement. For example:
        </p>
        <br />
        <code>
          {`let iceCream = 'chocolate';
             if(iceCream === 'chocolate') {
             alert('Yay, I love chocolate ice cream!');
           } else {
             alert('Awwww, but chocolate is my favorite...');
           }`}
        </code>
        <br />
        <p>
          The expression inside the if( ... ) is the test. This uses the
          identity operator (as described above) to compare the variable
          iceCream with the string chocolate to see if the two are equal. If
          this comparison returns true, the first block of code runs. If the
          comparison is not true, the second block of code after the else
          statement runs instead.
        </p>
      </div>
      <div className={styles.section}>
        <h2>Functions</h2>

        <p>
          Functions are a way of packaging functionality that you wish to reuse.
          It's possible to define a body of code as a function that executes
          when you call the function name in your code. This is a good
          alternative to repeatedly writing the same code. You have already seen
          some uses of functions. For example:
        </p>
        <br />
        <code>{`console.log('Hello world');`}</code>
        <br />
        <p>
          If you see something which looks like a variable name, but it's
          followed by parentheses— () —it is likely a function. Functions often
          take arguments: bits of data they need to do their job. Arguments go
          inside the parentheses, separated by commas if there is more than one
          argument.
        </p>

        <p>
          You can define your own functions. In the next example, we create a
          simple function which takes two numbers as arguments and multiplies
          them:
        </p>
        <br />
        <code>
          {`function multiply(num1, num2) {
             let result = num1 * num2;
             return result;
           }`}
        </code>
        <br />
        <p>You can then call this function, for example:</p>
        <br />
        <code>multiply(4, 7); multiply(20, 20); multiply(0.5, 3);</code>
      </div>
    </>
  );
}
