import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './NavbarItem.module.scss';

export default function NavbarItem(props) {
  const { label, icons, isExpanded, onClick, path } = props;
  const location = useLocation();
  const isActive = path && new RegExp(`${path}(/|$)`).test(location.pathname);
  const icon = isActive ? icons.active : icons.inactive;

  const navbarItem = (
    <div
      className={`${styles.navbarItem} ${isExpanded ? styles.expanded : ''} ${
        isActive ? styles.active : ''
      }`}
    >
      <div className={styles.icon}>{icon}</div>
      {isExpanded && <p className={styles.label}>{label}</p>}
    </div>
  );

  if (onClick) {
    return (
      <button type="button" onClick={onClick}>
        {navbarItem}
      </button>
    );
  }
  if (path) {
    return (
      <Link to={path} onClick={onClick}>
        {navbarItem}
      </Link>
    );
  }
  return navbarItem;
}
