const THREE = require('three');

module.exports = class Sensor {
  constructor(
    name,
    rayCaster,
    position,
    rotation,
    localPosition,
    localDirection,
    range
  ) {
    if (
      !(
        localPosition.isVector3 &&
        localDirection.isVector3 &&
        position.isVector3
      )
    ) {
      throw new Error(
        'Arguments provided to the Sensor constructor need to be of THREE.Vector3 type.'
      );
    }
    this.name = name;
    this.rayCaster = rayCaster;
    this.range = range;
    this.localPosition = localPosition;
    const maxRange = range < 0 ? Number.MAX_SAFE_INTEGER : range;
    this.localDirection = localDirection.setLength(maxRange);
    this.hit = false;
    this.distance = range;
  }

  update(position, rotation) {
    this.worldFrom = position.clone();

    const sensorOffsetWorld = this.localPosition.clone();
    sensorOffsetWorld.applyAxisAngle(new THREE.Vector3(1, 0, 0), rotation.x);
    sensorOffsetWorld.applyAxisAngle(new THREE.Vector3(0, 1, 0), rotation.y);
    sensorOffsetWorld.applyAxisAngle(new THREE.Vector3(0, 0, 1), rotation.z);

    this.worldFrom.add(sensorOffsetWorld);

    const sOff2 = this.localDirection.clone();
    sOff2.applyAxisAngle(new THREE.Vector3(1, 0, 0), rotation.x);
    sOff2.applyAxisAngle(new THREE.Vector3(0, 1, 0), rotation.y);
    sOff2.applyAxisAngle(new THREE.Vector3(0, 0, 1), rotation.z);

    this.worldTo = this.worldFrom.clone().add(sOff2);

    this.rayCaster.setRayFromWorld(
      this.worldFrom.x,
      this.worldFrom.y,
      this.worldFrom.z
    );
    this.rayCaster.setRayToWorld(
      this.worldTo.x,
      this.worldTo.y,
      this.worldTo.z
    );
  }

  test() {
    this.rayCaster.rayTest();
    if (this.rayCaster.hasHit()) {
      const hitPoints = this.rayCaster
        .getCollisionObjects()
        .map((obj, i) => {
          const { x, y, z } = this.rayCaster.getHitPointsWorld()[i];
          const { name } = obj;
          const hitPosition = new THREE.Vector3(x, y, z);
          return {
            name,
            hitPosition,
          };
        })
        .filter((obj) => obj.name !== 'goal');
      hitPoints.sort((a, b) => {
        const aDist = this.worldFrom.clone().sub(a.hitPosition).length();
        const bDist = this.worldFrom.clone().sub(b.hitPosition).length();
        return aDist - bDist;
      });

      if (hitPoints.length > 0) {
        this.hit = true;
        this.distance = this.worldFrom
          .clone()
          .sub(hitPoints[0].hitPosition)
          .length();
        this.hitPosition = hitPoints[0].hitPosition;
        return;
      }
    }
    this.hit = false;
    this.distance = this.range;
    this.hitPosition = this.worldTo;
  }
};
