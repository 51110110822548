import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import APIClient from '../../../../util/APIClient';
import styles from './GameList.module.scss';
import GameListEntry from './GameListEntry/GameListEntry';
import Loading from '../../Common/Loading/Loading';
import AuthenticatedRoute from '../../../_functional/AuthenticatedRoute';

function GameList() {
  const [code, setCode] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [games, setGames] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const reload = React.useCallback(() => {
    APIClient.get('/participant/games')
      .then((result) => {
        setGames(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  async function onSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await APIClient.post('/participant/games', { joinCode: code });
      const result = await APIClient.get('/participant/games');
      setGames(result.data);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Could not join game. Please contact support.');
    } finally {
      setIsSubmitting(false);
    }
  }

  React.useEffect(() => {
    APIClient.get('/participant/games')
      .then((result) => {
        setGames(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (games === null) {
    return <p className={styles.errorBanner}>Failed to load games.</p>;
  }

  return (
    <>
      <div className={styles.gameList}>
        <div className={styles.header}>
          <h1 className={styles.title}>Your games</h1>
          <div className={styles.horizontalFill} />
          <form className={styles.joinGame} onSubmit={onSubmit}>
            <label htmlFor="code">
              <input
                type="text"
                name="code"
                autoComplete="off"
                placeholder="Code"
                disabled={isSubmitting}
                value={code}
                onChange={(e) => {
                  e.preventDefault();
                  setCode(e.target.value);
                }}
              />
            </label>
            <input type="submit" value="Join game" disabled={isSubmitting} />
          </form>
          <button type="button" onClick={reload}>
            <RefreshIcon htmlColor="#999999" fontSize="inherit" />
          </button>
        </div>
        {games.map((game) => (
          <GameListEntry key={`game-${game.id}`} game={game} reload={reload} />
        ))}
        {games.length === 0 && (
          <div className={styles.noGames}>
            <p>You have not joined any game yet.</p>
          </div>
        )}
      </div>
    </>
  );
}

export default AuthenticatedRoute(GameList);
