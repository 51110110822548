/* eslint-disable no-new-func */
const ERROR_LINE_OFFSET = -2;

class UserCode {
  constructor(code) {
    this.code = code;
  }

  static _wrapUserCodeError(error) {
    const { message, stack } = error;
    let errorMessage = `${message}`;
    try {
      const errorLineRegex = /^\s*at.+<anonymous>:/;
      const stackLines = stack.split('\n');
      const matchingLines = stackLines.filter((line) =>
        errorLineRegex.test(line)
      );
      if (matchingLines.length > 0) {
        const [errorLine] = matchingLines[0]
          .replace(errorLineRegex, '')
          .replace(/[^:0-9]/, '')
          .split(':');
        const offsetErrorLine = Number(errorLine) + ERROR_LINE_OFFSET;
        errorMessage += `\nStack trace suggests error on line: ${offsetErrorLine}.`;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(
        'Failed to compute line offset! Returning raw error message.'
      );
    }
    return errorMessage;
  }

  getOnStartFunction() {
    const functionCode = [];
    functionCode.push(this.code);
    functionCode.push(`
    if(typeof onStart === 'function') { 
      return onStart;
    } else {
      throw new Error('User code needs to define an onStart function!');
    }`);

    const generator = new Function(functionCode.join('\n'));
    return generator();
  }

  getOnUpdateFunction() {
    const functionCode = [];
    functionCode.push(this.code);
    functionCode.push(`
    if(typeof onUpdate === 'function') { 
      return onUpdate;
    } else {
      throw new Error('User code needs to define an onUpdate function!');
    }`);

    const generator = new Function(functionCode.join('\n'));
    return generator();
  }
}

module.exports = UserCode;
