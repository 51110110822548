import React from 'react';
import styles from './Maps.module.scss';
import MapTile from './MapTile/MapTile';
import APIClient from '../../../util/APIClient';
import Loading from '../Common/Loading/Loading';

export default function Maps() {
  const [maps, setMaps] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    APIClient.get('/participant/maps')
      .then((result) => {
        setMaps(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (maps === null) {
    return <p className={styles.errorBanner}>Failed to load maps.</p>;
  }

  return (
    <div className={styles.maps}>
      <div className={styles.mapsWrapper}>
        {maps.map((map) => (
          <MapTile key={map.id} map={map} />
        ))}
      </div>
    </div>
  );
}
