import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import styles from './NavbarItemProfile.module.scss';
import superStyles from '../NavbarItem/NavbarItem.module.scss';
import AuthenticationContext from '../../../../../contexts/AuthenticationContext';

export default function NavbarItemProfile(props) {
  const { currentUser } = React.useContext(AuthenticationContext);
  const location = useLocation();
  const { path, isExpanded } = props;
  const isActive = path && new RegExp(`${path}(/|$)`).test(location.pathname);

  return (
    <Link to={path}>
      <div
        className={`${superStyles.navbarItem} ${
          isExpanded ? superStyles.expanded : ''
        }`}
      >
        <div className={superStyles.icon}>
          {(isActive && (
            <PersonIcon fontSize="inherit" htmlColor="#b01c2e" />
          )) || <PermIdentityIcon fontSize="inherit" htmlColor="#999999" />}
        </div>
        {isExpanded && (
          <div className={styles.userInfo}>
            <p className={styles.name}>
              {currentUser.attributes.given_name}{' '}
              {currentUser.attributes.family_name}
            </p>
            <p className={styles.emailAddress}>
              {currentUser.attributes.email}
            </p>
          </div>
        )}
      </div>
    </Link>
  );
}
