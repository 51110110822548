/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Documentation.module.scss';
import maps from '../../../../assets/images/help/help_maps.png';
import build_source_code from '../../../../assets/images/help/help_build_source_code.png';
import create_source_code from '../../../../assets/images/help/help_create_source_code.png';
import test_on from '../../../../assets/images/help/help_test_on.png';
import join_game from '../../../../assets/images/help/help_join_game.png';
import save_source_code from '../../../../assets/images/help/help_save_source_code.png';
import show_leaderboards from '../../../../assets/images/help/help_show_leaderboards.png';
import simulate_source_code from '../../../../assets/images/help/help_simulate_source_code.png';
import submit_source_code from '../../../../assets/images/help/help_submit_source_code.png';
import rename_source_code from '../../../../assets/images/help/help_rename_source_code.png';

export default function UserGuide() {
  return (
    <>
      <h1>User Guide</h1>
      <div className={styles.section}>
        <p>
          This section will help you navigate the application and explain how
          different components interact together. It will also outline the
          process of running the simulation, playing back the results and
          submitting your code for scoring.
        </p>
      </div>
      <div className={styles.section}>
        <h2>Navigating the application</h2>
        <p>
          This application requires you to{' '}
          <Link to="/account/signUp">sign up</Link> in order to access all of
          its features. After creating an account, you will need to verify your
          email, by clicking the link that was included in the confirmation
          email that has been sent to your email address. Once confirmed, you
          will be able to <Link to="/account/signIn">sign in</Link> and use the
          application.
        </p>
        <br />
        <p>
          Upon signing in, you will be presented with several additional pages
          accessible through the navigation bar on the left-hand side of the
          screen. Selecting the{' '}
          <span className={styles.bold}>Documentation</span> will additionally
          display a list of sub-pages for you to explore.
        </p>
      </div>
      <div className={styles.section}>
        <h2>Creating your first source code</h2>
        <p>
          To create your first code entry, simply navigate to the{' '}
          <Link to="/maps">Maps</Link> page and select the map on which you
          would like to start.
        </p>
        <img src={maps} alt="Maps screen" className={styles.image} />
        <p>
          The source code entry <span className={styles.bold}>will not</span> be
          bound to that map! Once you save your code, you will be able to test
          the code you write on any map.
        </p>
        <img
          src={test_on}
          alt="Test source code on a selected map screen"
          className={styles.image}
        />
        <p>
          Alternatively, you can navigate to the{' '}
          <Link to="/sourceCode">My Code</Link> page to create a new code entry.
          This is also where you will see all your saved source code entries and
          test them on any available map. It is important to note, that every
          time you select one of the maps in the <Link to="/maps">Maps</Link>{' '}
          page, you will create a new source code (which will appear in your
          source code list).
        </p>
        <img
          src={create_source_code}
          alt="Create source code screen"
          className={styles.image}
        />
        <p>
          In order to keep your different attempts organised, you can name each
          of your source code entries you create. To do so, simply open the code
          on any map and use the pencil button on the top left-hand side of the
          screen to enable the source code editing.{' '}
          <span className={styles.bold}>
            You will have to save your code after changing its name!
          </span>
        </p>
        <img
          src={rename_source_code}
          alt="Rename source code screen"
          className={styles.image}
        />
        <p>
          To save your code, use the floppy disk icon on the right-hand side of
          the screen, just above your code editor.
        </p>
        <img
          src={save_source_code}
          alt="Save source code screen"
          className={styles.image}
        />
      </div>
      <div className={styles.section}>
        <h2>Writing source code</h2>
        <p>
          In order to interact with the simulation, use the code editor
          provided. This is where you can write your JavaScript code to direct
          the airship through the map and towards the goal (that's the blue
          sphere!).
        </p>
        <br />
        <p>
          <span className={styles.bold}>
            A key part of writing a good code to guide your vehicle is
            understanding when your code is being executed.
          </span>{' '}
        </p>
        <br />
        <p>
          As such, there are two main functions for your to take note of:
          <br />- <span className={styles.code}>onStart(vehicle)</span>, which
          will will execute only once, at the start of the simulation.
          <br />- <span className={styles.code}>onUpdate(vehicle)</span>, which
          will execute at every next step of the simulation.
        </p>
        <br />
        <p>
          Even if you are not planning on using either of these two functions,
          you always have to define both of them. This is to ensure that your
          code can be safely passed to the simulator program behind the scenes.
          If you're not sure what functions you can call on the airship,
          remember to check the{' '}
          <Link to="/documentation/apiReference">API Reference</Link> for a
          rundown of available commands.
        </p>
      </div>
      <div className={styles.section}>
        <h2>Running the simulation</h2>
        <p>
          Once you're happy with your code, you can test it by running the
          simulation! Before that can happen however, you will need to pass your
          source code to the simulator. This means that the two functions you
          have defined in your editor will be extracted and used separately when
          needed.
        </p>
        <img
          src={build_source_code}
          alt="Build source code screen"
          className={styles.image}
        />
        <p>
          To pass your code to the simulator, simply press the{' '}
          <span className={styles.bold}>Build</span> button on the right-hand
          side of the screen, above the code editor and next to the save button.
        </p>
        <img
          src={build_source_code}
          alt="Build source code screen"
          className={styles.image}
        />
        <p>
          Once your function is build successfully, the{' '}
          <span className={styles.bold}>Simulate</span> button will become
          enabled and you will be able to run the simulation.
        </p>

        <img
          src={simulate_source_code}
          alt="Simulate source code screen"
          className={styles.image}
        />
        <p className={styles.large}>
          <span className={styles.bold}>
            Don't worry if this takes some time! If you can't interact with the
            website while the simulation is running, do not be alarmed! That's
            because the simulation is a computationally expensive operation.
          </span>
        </p>
        <br />
        <p>
          When the simulation completes, the playback will start automatically.
          You will also be able to replay, pause, rewind and skip through the
          simulated run of your airship afterwards without having to run the
          simulation again, unless you change your code. Every time you modify
          the source code, you will need to run the simulation again from the
          start; this is to ensure that the playback of your simulation reflects
          the results of the code you have written.
        </p>
      </div>
      <div className={styles.section}>
        <h2>Submitting your code for scoring</h2>
        <p>
          Once you are happy with your simulation, you will be able to submit it
          for scoring to a game you have joined. To do so, use the dropdown at
          the top right-hand side of the screen to select a game to which you
          want to submit and press the{' '}
          <span className={styles.bold}>Submit to game</span> button.
        </p>
        <img
          src={submit_source_code}
          alt="Submit source code screen"
          className={styles.image}
        />
        <p>
          If you haven't yet joined a game, you will not be able to submit your
          code. Don't worry, you can join a game any time by navigating to{' '}
          <Link to="/games">My Games</Link> page and using the form on the right
          side to join the game, using the join-code provided to you.
        </p>
        <img src={join_game} alt="Join game screen" className={styles.image} />
      </div>
      <div className={styles.section}>
        <h2>Displaying leaderboards</h2>
        <p>
          Finally, once your code is submitted successfully, you it will be
          saved, compiled and queued up for scoring. You can navigate to the
          leaderboards for a game you have joined by heading over to{' '}
          <Link to="/games">My Games</Link> and pressing the leaderboard icon
          next to a game you have joined to view the leaderboards. There, you
          should see your username submission under a map name you submitted
          your source code for, along with the status of your scoring results.
        </p>
        <img
          src={show_leaderboards}
          alt="Show leaderboards screen"
          className={styles.image}
        />
      </div>
    </>
  );
}
