import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/_layouts/Layout/Layout';
import SourceCodeLayout from './components/_view/SourceCode/SourceCodeLayout';
import Leaderboards from './components/_view/Leaderboards/Leaderboards';
import Maps from './components/_view/Maps/Maps';
import DocumentationLayout from './components/_view/Documentation/DocumentationLayout';
import AccountLayout from './components/_view/Account/AccountLayout';
import AdminLayout from './components/_view/Admin/AdminLayout';
import GamesList from './components/_view/Games/GameList/GameList';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate replace to="/documentation" />} />
          <Route path="/maps" element={<Maps />} />
          <Route path="/documentation/*" element={<DocumentationLayout />} />
          <Route path="/leaderboards" element={<Leaderboards />} />
          <Route path="/sourceCode/*" element={<SourceCodeLayout />} />
          <Route path="/games/*" element={<GamesList />} />
          <Route path="/account/*" element={<AccountLayout />} />
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route path="*" element={<p>404</p>} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
