const THREE = require('three');
const Sensor = require('./Sensor');

module.exports = class Vehicle {
  constructor(physics, object, vehicleConfig, mapConfig) {
    this.physics = physics;
    this.object = object;
    this.mapConfig = mapConfig;
    this.sensors = [];
    this.throttle = 0;
    this.rudder = 0;
    this.dragCoefficient = vehicleConfig.dragCoefficient;
    this.throttleCoefficient = vehicleConfig.throttleCoefficient;
    this.rudderCoefficient = vehicleConfig.rudderCoefficient;
    this.maxSpeed = vehicleConfig.maxSpeed;
    this.orientation = new THREE.Vector3(
      vehicleConfig.orientation[0],
      vehicleConfig.orientation[1],
      vehicleConfig.orientation[2]
    ).normalize();
    this.rudderAxis = new THREE.Vector3(
      vehicleConfig.rudderAxis[0],
      vehicleConfig.rudderAxis[1],
      vehicleConfig.rudderAxis[2]
    ).normalize();
    this._initSensors(vehicleConfig.sensors);
  }

  _initSensors(sensors) {
    this.sensors = sensors.map((sensor) => {
      const rayCaster = this.physics.add.raycaster('allHits');
      const position = new THREE.Vector3(
        this.mapConfig.vehicle.position[0],
        this.mapConfig.vehicle.position[1],
        this.mapConfig.vehicle.position[2]
      );
      const rotation = new THREE.Vector3(
        this.mapConfig.vehicle.rotation[0],
        this.mapConfig.vehicle.rotation[1],
        this.mapConfig.vehicle.rotation[2]
      );
      const localPosition = new THREE.Vector3(
        sensor.offsetPosition[0],
        sensor.offsetPosition[1],
        sensor.offsetPosition[2]
      );
      const localDirection = new THREE.Vector3(
        sensor.direction[0],
        sensor.direction[1],
        sensor.direction[2]
      );
      return new Sensor(
        sensor.name,
        rayCaster,
        position,
        rotation,
        localPosition,
        localDirection,
        sensor.range
      );
    });
    const position = new THREE.Vector3(
      this.mapConfig.vehicle.position[0],
      this.mapConfig.vehicle.position[1],
      this.mapConfig.vehicle.position[2]
    );
    const rotation = new THREE.Vector3(
      this.mapConfig.vehicle.rotation[0],
      this.mapConfig.vehicle.rotation[1],
      this.mapConfig.vehicle.rotation[2]
    );
    this.sensors.forEach((s) => {
      s.update(position, rotation);
      s.test();
    });
  }

  get drag() {
    const dragFactor = this.speed * this.dragCoefficient;
    return this.velocity
      .clone()
      .normalize()
      .multiplyScalar(dragFactor * -1);
  }

  get speed() {
    return this.velocity.length();
  }

  get direction() {
    return this.orientation.clone().applyQuaternion(this.object.quaternion);
  }

  get velocity() {
    return new THREE.Vector3(
      this.object.body.velocity.x,
      this.object.body.velocity.y,
      this.object.body.velocity.z
    );
  }

  setRudder(value) {
    if (value < -1 || value > 1) {
      throw new Error(
        'Invalid argument passed! Rudder has to be between -1 and 1.'
      );
    }
    this.rudder = value;
  }

  setThrottle(value) {
    if (value < -1 || value > 1) {
      throw new Error(
        'Invalid argument passed! Throttle has to be between -1 and 1.'
      );
    }
    this.throttle = value;
  }

  _getGps() {
    return {
      x: this.object.position.x,
      z: this.object.position.z,
    };
  }

  _getAltitude() {
    const altitude = {
      seaLevel: this.object.position.y,
    };

    const altitudeSensors = this.sensors.filter((s) => s.name === 'altitude');
    if (altitudeSensors.length === 1) {
      altitude.ground = altitudeSensors[0].distance;
    } else {
      altitude.ground = Number.NaN;
    }

    return altitude;
  }

  _getSpeed() {
    const { velocity } = this.object.body;
    return new THREE.Vector3(velocity.x, velocity.y, velocity.z).length();
  }

  _getCompass() {
    const flatDirection = this.direction.clone().setY(0);
    const north = new THREE.Vector3(1, 0, 0);
    const west = new THREE.Vector3(0, 0, 1);

    const degreesFromNorth = (north.angleTo(flatDirection) / Math.PI) * 180;
    const degreesFromWest = (west.angleTo(flatDirection) / Math.PI) * 180;

    // Add 180 degrees if the vehicle's direction is between north and south on the west side.
    const offset = Math.abs(degreesFromWest) < 180 ? 180 : 0;

    return Math.round(degreesFromNorth + offset);
  }

  getApi() {
    return {
      gps: this._getGps(),
      altitude: this._getAltitude(),
      compass: this._getCompass(),
      speed: this._getSpeed(),
      velocity: {
        x: this.velocity.x,
        y: this.velocity.y,
        z: this.velocity.z,
      },
      direction: {
        x: this.direction.x,
        y: this.direction.y,
        z: this.direction.z,
      },
      drag: {
        x: this.drag.x,
        y: this.drag.y,
        z: this.drag.z,
      },
      sensors: this.sensors.map((s) => ({
        name: s.name,
        hit: s.hit,
        distance: s.distance,
      })),
      setRudder: (value) => this.setRudder(value),
      setThrottle: (value) => this.setThrottle(value),
    };
  }

  _updateSensors() {
    this.sensors.forEach((sensor) => {
      sensor.update(
        new THREE.Vector3(
          this.object.position.x,
          this.object.position.y,
          this.object.position.z
        ),
        new THREE.Vector3(
          this.object.rotation.x,
          this.object.rotation.y,
          this.object.rotation.z
        )
      );
      sensor.test();
    });
  }

  _applyRudder() {
    const { angularVelocity } = this.object.body;

    const appliedAngularVelocity = new THREE.Vector3(
      angularVelocity.x,
      angularVelocity.y,
      angularVelocity.z
    );

    const newRudderAngularVelocity = this.rudderCoefficient * this.rudder;

    appliedAngularVelocity.setY(newRudderAngularVelocity);

    this.object.body.setAngularVelocity(
      appliedAngularVelocity.x,
      appliedAngularVelocity.y,
      appliedAngularVelocity.z
    );
  }

  _applyThrottle() {
    const appliedForce = this.direction.clone();
    // Ensure that the vehicle does not exceed the max speed by capping the delta at 0
    // i.e. during the update the vehicle could overshoot the max speed.
    const deltaToMaxSpeed = Math.max(
      (1 + this.dragCoefficient) * this.maxSpeed - this.speed,
      0
    );

    // console.log({
    //   deltaToMaxSpeed,
    //   appliedForce: [appliedForce.x, appliedForce.y, appliedForce.z],
    //   multiplier: this.throttleCoefficient * this.throttle * deltaToMaxSpeed,
    // });

    const multiplier =
      this.throttleCoefficient * this.throttle * deltaToMaxSpeed +
      this.drag.length();

    appliedForce.multiplyScalar(multiplier);

    this.object.body.applyForce(appliedForce.x, appliedForce.y, appliedForce.z);
  }

  _applyDrag() {
    const { drag } = this;

    this.object.body.applyForce(drag.x, drag.y, drag.z);
  }

  update() {
    this._updateSensors();
    this._applyRudder();
    this._applyThrottle();
    this._applyDrag();
  }
};
