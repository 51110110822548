import React from 'react';
import SimulatorContext from '../../../../contexts/SimulatorContext';

function SimulatorProvider(props) {
  const { children } = props;
  const [state, setState] = React.useState({});

  return (
    <SimulatorContext.Provider value={[state, setState]}>
      {children}
    </SimulatorContext.Provider>
  );
}

export default SimulatorProvider;
