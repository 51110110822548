import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AdminCreateGame.module.scss';
import APIClient from '../../../../util/APIClient';
import Loading from '../../Common/Loading/Loading';

export default function AdminCreateGame() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [name, setName] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [maps, setMaps] = React.useState(null);
  const [selectedMapIds, setSelectedMapIds] = React.useState([]);
  const navigate = useNavigate();

  async function onSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await APIClient.post('/administrator/games', {
        name,
        isOpen,
        mapIds: selectedMapIds,
      });
      navigate('/admin/games');
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Could not create game. Please contact support.');
    } finally {
      setIsSubmitting(false);
    }
  }

  function updateSelectedMaps(e, mapId) {
    setSelectedMapIds((mapIds) => {
      let newMaps;
      if (e.target.value) {
        newMaps = [...mapIds, mapId];
      } else {
        newMaps = mapIds.filter((mId) => mId !== mapId);
      }
      return [...new Set(newMaps)];
    });
  }

  React.useEffect(() => {
    APIClient.get('/administrator/maps')
      .then((result) => {
        setMaps(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (maps === null) {
    return <p className={styles.errorBanner}>Failed to load maps.</p>;
  }

  return (
    <div className={styles.createGame}>
      <div className={styles.formBox}>
        <h1>Create game</h1>
        <form onSubmit={onSubmit}>
          <label htmlFor="name">
            Name:
            <input
              type="text"
              name="name"
              autoComplete="off"
              value={name}
              onChange={(e) => {
                e.preventDefault();
                setName(e.target.value);
              }}
              disabled={isSubmitting}
            />
          </label>
          <label htmlFor="isOpen" className={styles.checkboxLabel}>
            <input
              type="checkbox"
              name="isOpen"
              value={isOpen}
              defaultChecked={isOpen}
              onChange={(e) => {
                setIsOpen(e.target.value);
              }}
              disabled={isSubmitting}
            />
            Open to submissions and joining
          </label>
          <p>Maps:</p>
          {maps.map((map) => {
            const matchingMaps = selectedMapIds.filter(
              (mapId) => mapId === map.id
            );
            const currentValue = matchingMaps.length === 1;
            return (
              <label
                htmlFor={map.id}
                key={`checkbox_${map.id}`}
                className={styles.checkboxLabel}
              >
                <input
                  type="checkbox"
                  name={`map_${map.id}`}
                  autoComplete="off"
                  value={currentValue}
                  onChange={(e) => updateSelectedMaps(e, map.id)}
                  disabled={isSubmitting}
                />
                {`${map.name} (${map.difficulty})`}
              </label>
            );
          })}
          <input type="submit" value="Create game" disabled={isSubmitting} />
        </form>
      </div>
    </div>
  );
}
