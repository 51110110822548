import React from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import styles from '../AccountLayout.module.scss';
import AccountFormContext from '../../../../contexts/AccountFormContext';
import UnauthenticatedRoute from '../../../_functional/UnauthenticatedRoute';

function SignIn() {
  const { setMessageType, setMessage } = React.useContext(AccountFormContext);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  async function onSubmit(e) {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      setMessageType('normal');
      setMessage('Signing in...');
      await Auth.signIn({
        username: email,
        password,
      });
    } catch (error) {
      setMessageType('error');
      setMessage(
        'Failed to sign-in. Please ensure that the details you have entered are correct and you' +
          ' have confirmed your email address.'
      );
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <h1>Sign in</h1>
      <form onSubmit={onSubmit}>
        <label htmlFor="email">
          Email address:
          <input
            type="email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => {
              e.preventDefault();
              setEmail(e.target.value);
            }}
          />
        </label>
        <label htmlFor="password">
          Password:
          <input
            type="password"
            name="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => {
              e.preventDefault();
              setPassword(e.target.value);
            }}
          />
        </label>
        <input type="submit" value="Sign In" disabled={isSubmitting} />
      </form>
      <p className={styles.formText}>
        Need an account? <Link to="/account/signUp">Sign up</Link> instead.
      </p>
      <p className={styles.formText}>
        <span className={styles.small}>
          Or if you forgot your password,{' '}
          <Link to="/account/forgotPassword">request a password reset</Link>.
        </span>
      </p>
    </>
  );
}

export default UnauthenticatedRoute(SignIn);
