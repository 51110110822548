import React from 'react';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import styles from './GameListEntry.module.scss';
import APIClient from '../../../../../util/APIClient';

export default function GameListEntry(props) {
  const {
    game: { id, name },
    reload,
  } = props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  async function leaveGame(e) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await APIClient.patch(`/participant/games/${id}`);
      reload();
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Could not leave game. Please contact support.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className={styles.gameListEntry}>
      <div className={styles.gameHeader}>
        <p className={styles.name}>{name}</p>
        <div className={styles.horizontalFill} />
        <Link className={styles.link} to={`/leaderboards?gameId=${id}`}>
          <LeaderboardIcon htmlColor="#999999" fontSize="inherit" />
        </Link>
        <button type="button" onClick={leaveGame} disabled={isSubmitting}>
          <LogoutIcon htmlColor="#999999" fontSize="inherit" />
        </button>
      </div>
    </div>
  );
}
