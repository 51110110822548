import React from 'react';
import { Canvas } from '@react-three/fiber';
import {
  GizmoHelper,
  GizmoViewport,
  PerspectiveCamera,
  OrbitControls,
  useGLTF,
  Line,
} from '@react-three/drei';
import { Utilities } from '@airship/simulator';
import useSimulator from '../../../../../hooks/useSimulator';

function createMap(mapConfig, model) {
  return <primitive object={model.scene} />;
}

function createVehicle(mapConfig, vehicleConfig, model, meshRef) {
  return (
    <primitive
      ref={meshRef}
      position={mapConfig.vehicle.position}
      rotation={mapConfig.vehicle.rotation}
      object={model.scene}
    />
  );
}

function createGoal(map) {
  const { position, radius } = map.goal;
  return (
    <mesh position={position}>
      <sphereGeometry args={[radius, 16, 16]} />
      <meshStandardMaterial transparent opacity={0.5} color="#29b0ff" />
    </mesh>
  );
}

function Viewport(props, ref) {
  const { configId, colliderMeshes } = props;

  const mapConfig = Utilities.getMapConfigByConfigId(configId);
  const vehicleConfig = Utilities.getVehicleConfigById(
    mapConfig.vehicle.vehicleId
  );
  const { frames } = useSimulator();

  const mapModel = useGLTF(mapConfig.model.file.default);
  const vehicleModel = useGLTF(vehicleConfig.model.file.default);

  const vehicleRef = React.useRef(null);
  const cameraRef = React.useRef(null);
  const controlsRef = React.useRef(null);

  const vehicle = createVehicle(
    mapConfig,
    vehicleConfig,
    vehicleModel,
    vehicleRef
  );
  const map = createMap(mapConfig, mapModel);
  const goal = createGoal(mapConfig);

  const [currentFrame, setCurrentFrame] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    // setFrames(frames) {
    //   setSimulationFrames(frames);
    // },

    setCurrentFrame(frameNumber) {
      setCurrentFrame(frameNumber);
    },

    stepForward() {
      setCurrentFrame((c) => (c < frames.length - 1 ? c + 1 : c));
    },

    stepBack() {
      setCurrentFrame((c) => (c > 0 ? c - 1 : c));
    },

    play() {
      setIsPlaying(true);
    },

    pause() {
      setIsPlaying(false);
    },
  }));

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (isPlaying) {
        setCurrentFrame((cf) => {
          if (cf < frames.length - 1) {
            return cf + 1;
          }
          setIsPlaying(false);
          return cf;
        });
      }
    }, 1000 / 60);
    return () => clearInterval(interval);
  }, [isPlaying, frames]);

  React.useEffect(() => {
    if (
      vehicleRef.current &&
      frames.length > 0 &&
      currentFrame < frames.length
    ) {
      vehicleRef.current.position.set(
        frames[currentFrame].vehicle.position[0],
        frames[currentFrame].vehicle.position[1],
        frames[currentFrame].vehicle.position[2]
      );
      vehicleRef.current.rotation.set(
        frames[currentFrame].vehicle.rotation[0],
        frames[currentFrame].vehicle.rotation[1],
        frames[currentFrame].vehicle.rotation[2]
      );
      // cameraRef.current.position.set(
      //   vehicleRef.current.position.x - 50,
      //   vehicleRef.current.position.y + 50,
      //   vehicleRef.current.position.z + 100
      // );
      // controlsRef.current.target.set(
      //   vehicleRef.current.position.x,
      //   vehicleRef.current.position.y,
      //   vehicleRef.current.position.z
      // );
    }
  }, [colliderMeshes, currentFrame, frames, mapConfig]);

  function getSensorLines() {
    if (
      frames &&
      frames[currentFrame] &&
      frames[currentFrame].vehicle &&
      frames[currentFrame].vehicle.sensors
    ) {
      return frames[currentFrame].vehicle.sensors.map((s) => (
        <Line
          points={[
            [s.from.x, s.from.y, s.from.z],
            [s.to.x, s.to.y, s.to.z],
          ]}
          color={s.hit ? 'red' : 'cyan'}
          key={s.name}
        />
      ));
    }
    return <></>;
  }

  return (
    <>
      <Canvas>
        <ambientLight />
        <directionalLight position={[0, 1, 1]} />
        <PerspectiveCamera
          makeDefault
          ref={cameraRef}
          position={[
            mapConfig.vehicle.position[0] - 50,
            mapConfig.vehicle.position[1] + 50,
            mapConfig.vehicle.position[2] + 100,
          ]}
        />
        {vehicle}
        {map}
        {goal}
        {getSensorLines()}
        {colliderMeshes
          .filter((mesh) => mesh.name !== vehicleConfig.nodeId)
          .map((mesh) => (
            <primitive object={mesh} key={mesh.uuid} />
          ))}
        {colliderMeshes
          .filter((mesh) => mesh.name === vehicleConfig.nodeId)
          .map((mesh) => {
            mesh.position.set(
              frames[currentFrame].vehicle.position[0],
              frames[currentFrame].vehicle.position[1],
              frames[currentFrame].vehicle.position[2]
            );
            mesh.rotation.set(
              frames[currentFrame].vehicle.rotation[0],
              frames[currentFrame].vehicle.rotation[1],
              frames[currentFrame].vehicle.rotation[2]
            );
            return <primitive object={mesh} key={mesh.uuid} />;
          })}
        <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
          <GizmoViewport
            axisColors={['red', 'green', 'blue']}
            labelColor="black"
          />
        </GizmoHelper>
        <OrbitControls
          enablePan
          enableZoom
          enableRotate
          ref={controlsRef}
          target={mapConfig.vehicle.position}
        />
      </Canvas>
    </>
  );
}

export default React.forwardRef(Viewport);
