/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Documentation.module.scss';
import splash from '../../../../assets/images/splash.png';

export default function GettingStarted() {
  return (
    <>
      <h1>Getting started</h1>
      <div className={styles.section}>
        <img src={splash} alt="Splash screen" className={styles.image} />
        <p>
          We have prepared a simple programming game which we hope you will
          enjoy. The game involves using basic JavaScript code in order to
          control an airship (or a fancy hot air balloon) and get it from the
          starting point, to the goal.
        </p>
      </div>
      <div className={styles.section}>
        <p>
          If you're not sure where to start, you can read the{' '}
          <Link to="/documentation/apiReference">API Reference</Link> to see how
          to interact with the vehicle. You can also read the{' '}
          <Link to="/documentation/javascriptBasics">JavaScript Basics</Link>{' '}
          section of the documentation if you are not familiar with programming
          in JavaScript.
        </p>
        <br />
        <p>
          Finally, if you find yourself struggling with running the simulations,
          or simply want to learn how to interact with this application, have a
          look at the <Link to="/documentation/userGuide">User Guide</Link>{' '}
          section.
        </p>
      </div>
    </>
  );
}
