import MenuBookIcon from '@mui/icons-material/MenuBook';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import superStyles from '../NavbarItem/NavbarItem.module.scss';
import styles from './NavbarItemDocumentation.module.scss';

function DocumentationItem(props) {
  const { path, label } = props;
  const location = useLocation();
  const isActive = path === location.pathname;

  return (
    <div className={`${styles.item}`}>
      <Link to={path} className={isActive ? styles.active : ''}>
        {label}
      </Link>
    </div>
  );
}

export default function NavbarItemDocumentation(props) {
  const { isExpanded, path } = props;
  const location = useLocation();
  const isActive = path && new RegExp(`${path}(/|$)`).test(location.pathname);
  const icon = isActive ? (
    <MenuBookIcon fontSize="inherit" htmlColor="#b01c2e" />
  ) : (
    <MenuBookOutlinedIcon fontSize="inherit" htmlColor="#999999" />
  );

  return (
    <>
      <Link to={path}>
        <div
          className={`${superStyles.navbarItem} ${
            isExpanded ? superStyles.expanded : ''
          } ${isActive ? superStyles.active : ''}`}
        >
          <div className={superStyles.icon}>{icon}</div>
          {isExpanded && <p className={superStyles.label}>Documentation</p>}
        </div>
      </Link>
      {isActive && isExpanded && (
        <div className={styles.documentationList}>
          <DocumentationItem
            path="/documentation/gettingStarted"
            label="Getting Started"
          />
          <DocumentationItem
            path="/documentation/userGuide"
            label="User Guide"
          />
          <DocumentationItem
            path="/documentation/javascriptBasics"
            label="JavaScript Basics"
          />
          <DocumentationItem
            path="/documentation/apiReference"
            label="API Reference"
          />
        </div>
      )}
    </>
  );
}
