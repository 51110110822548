import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './AdminShareJoinCode.module.scss';
import APIClient from '../../../../util/APIClient';
import Loading from '../../Common/Loading/Loading';
import airshipGif from '../../../../assets/images/airship.gif';

export default function AdminShareJoinCode() {
  const { gameId } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [game, setGame] = React.useState(null);

  React.useEffect(() => {
    APIClient.get(`/administrator/games/${gameId}`)
      .then((result) => {
        setGame(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [gameId]);

  if (isLoading) {
    return <Loading />;
  }

  if (game === null) {
    return <p>Failed to load game!</p>;
  }

  return (
    <div className={styles.fullScreenWrapper}>
      <img src={airshipGif} alt="Flying airship." />
      <p>Join using code:</p>
      <p className={styles.joinCode}>{game.joinCode}</p>
    </div>
  );
}
