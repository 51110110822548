import React from 'react';
import { Auth } from 'aws-amplify';
import { useSearchParams } from 'react-router-dom';
import UnauthenticatedRoute from '../../../_functional/UnauthenticatedRoute';
import AccountFormContext from '../../../../contexts/AccountFormContext';

function ResetPassword() {
  const { setMessage, setMessageType } = React.useContext(AccountFormContext);
  const [searchParams] = useSearchParams();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasInitialProvidedValues] = React.useState(
    !!(searchParams.get('code') && searchParams.get('email'))
  );
  const [username, setUsername] = React.useState(
    searchParams.get('email') || ''
  );
  const [code, setCode] = React.useState(searchParams.get('code') || '');
  const [password, setPassword] = React.useState('');
  const [repeatPassword, setRepeatPassword] = React.useState('');

  async function onSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await Auth.forgotPasswordSubmit(username, code, repeatPassword);
      setMessageType('success');
      setMessage('The password has been reset.');
    } catch (error) {
      setMessageType('error');
      setMessage('The password could not be reset.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <h1>Reset password</h1>
      <code>
        {JSON.stringify({ username, code, password, repeatPassword }, null, 2)}
      </code>
      <form onSubmit={onSubmit}>
        <label htmlFor="username" hidden={hasInitialProvidedValues}>
          Email address:
          <input
            type="email"
            name="username"
            autoComplete="off"
            value={username}
            onChange={(e) => {
              e.preventDefault();
              setUsername(e.target.value);
            }}
          />
        </label>
        <label htmlFor="code" hidden={hasInitialProvidedValues}>
          Code:
          <input
            type="text"
            name="code"
            autoComplete="off"
            value={code}
            onChange={(e) => {
              e.preventDefault();
              setCode(e.target.value);
            }}
          />
        </label>
        <label htmlFor="password">
          New password:
          <input
            type="password"
            name="password"
            autoComplete="off"
            value={password}
            onChange={(e) => {
              e.preventDefault();
              setPassword(e.target.value);
            }}
          />
        </label>
        <label htmlFor="repeatPassword">
          Repeat new password:
          <input
            type="password"
            name="repeatPassword"
            autoComplete="off"
            value={repeatPassword}
            onChange={(e) => {
              e.preventDefault();
              setRepeatPassword(e.target.value);
            }}
          />
        </label>
        <input type="submit" value="Change password" disabled={isSubmitting} />
      </form>
    </>
  );
}

export default UnauthenticatedRoute(ResetPassword);
