import React from 'react';
import { Link } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import APIClient from '../../../../util/APIClient';
import styles from './AdminGameList.module.scss';
import AdminGameListEntry from './AdminGameListEntry/AdminGameListEntry';
import Loading from '../../Common/Loading/Loading';

export default function AdminGameList() {
  const [gameEntries, setGameEntries] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const reload = React.useCallback(() => {
    APIClient.get('/administrator/games')
      .then((result) => {
        setGameEntries(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    APIClient.get('/administrator/games')
      .then((result) => {
        setGameEntries(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (gameEntries === null) {
    return <p className={styles.errorBanner}>Failed to load games.</p>;
  }

  return (
    <>
      <div className={styles.gameList}>
        <div className={styles.header}>
          <h1 className={styles.title}>Games</h1>
          <div className={styles.horizontalFill} />
          <Link className={styles.newGame} to="/admin/games/create">
            Create game
          </Link>
          <button type="button" onClick={reload}>
            <RefreshIcon htmlColor="#999999" fontSize="inherit" />
          </button>
        </div>
        {gameEntries.map((game) => (
          <AdminGameListEntry
            key={game.id}
            id={game.id}
            name={game.name}
            isOpen={game.isOpen}
            reload={reload}
          />
        ))}
        {gameEntries.length === 0 && (
          <div className={styles.noGames}>
            <p>There are no games yet.</p>
          </div>
        )}
      </div>
    </>
  );
}
