import React from 'react';
import SimulatorContext from '../contexts/SimulatorContext';

function useSimulator() {
  const [state, setState] = React.useContext(SimulatorContext);

  const setCurrentFrameNumber = React.useCallback(
    (currentFrameNumber) => {
      setState((s) => ({ ...s, currentFrameNumber }));
    },
    [setState]
  );

  const setFrames = React.useCallback(
    (frames) => {
      setState((s) => ({ ...s, frames }));
    },
    [setState]
  );

  return {
    setCurrentFrameNumber,
    currentFrameNumber: state.currentFrameNumber,
    setFrames,
    frames: state.frames,
  };
}

export default useSimulator;
