import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import styles from './AccountLayout.module.scss';
import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import ResetPassword from './ResetPassword/ResetPassword';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Profile from './Profile/Profile';
import AccountFormContext from '../../../contexts/AccountFormContext';
import ChangePassword from './ChangePassword/ChangePassword';
import CompleteEmailChangeAutomatic from './CompleteEmailChange/CompleteEmailChangeAutomatic';
import VerifyEmailAutomatic from './VerifyEmail/VerifyEmailAutomatic';

function AccountLayoutWrapper() {
  const { message, messageType } = React.useContext(AccountFormContext);
  const messageClass = styles[messageType];

  return (
    <div className={styles.signLayout}>
      <Outlet />
      {message && (
        <p className={styles.message}>
          <span className={messageClass}>{message}</span>
        </p>
      )}
    </div>
  );
}

function AccountFormLayout() {
  return (
    <div className={styles.formBox}>
      <Outlet />
    </div>
  );
}

function AccountLayout() {
  const [message, setMessage] = React.useState('');
  const [messageType, setMessageType] = React.useState('');

  const setMessageTypeCb = React.useCallback((e) => setMessageType(e), []);
  const setMessageCb = React.useCallback((m) => setMessage(m), []);

  return (
    <AccountFormContext.Provider
      value={{
        message,
        setMessage: setMessageCb,
        messageType,
        setMessageType: setMessageTypeCb,
      }}
    >
      <Routes>
        <Route element={<AccountLayoutWrapper />}>
          <Route element={<AccountFormLayout />}>
            <Route index element={<SignIn />} />
            <Route path="signIn" element={<SignIn />} />
            <Route path="signUp" element={<SignUp />} />
            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route path="completePasswordReset" element={<ResetPassword />} />
            <Route path="profile" element={<Profile />} />
            <Route path="changePassword" element={<ChangePassword />} />
          </Route>
          <Route
            path="completeEmailChange"
            element={<CompleteEmailChangeAutomatic />}
          />
          <Route path="verifyEmail" element={<VerifyEmailAutomatic />} />
        </Route>
      </Routes>
    </AccountFormContext.Provider>
  );
}

export default AccountLayout;
