const vehicles = [
  {
    id: 'airship',
    nodeId: 'vehicle',
    model: {
      filename: './models/airship.glb',
      get file() {
        return require('./models/airship.glb');
      },
    },
    orientation: [1, 0, 0],
    rudderAxis: [0, 1, 0],
    // How much vehicle is affected by its own drag (0, 1)
    dragCoefficient: 0.07,
    // How efficient throttle is in reaching the max speed (0, 1)
    throttleCoefficient: 0.01,
    // How efficient the rudder is at applying angular velocity in Y axis (0, 1)
    rudderCoefficient: 0.15,
    maxSpeed: 10,
    sensors: [
      {
        name: 'right',
        offsetPosition: [0, -0.1, 1.7],
        direction: [0, 0, 1],
        range: 30,
      },
      {
        name: 'frontRight',
        offsetPosition: [0, -0.1, 1.7],
        direction: [2, 0, 1],
        range: 50,
      },
      {
        name: 'front',
        offsetPosition: [5, 0, 0],
        direction: [1, 0, 0],
        range: 60,
      },
      {
        name: 'left',
        offsetPosition: [0, -0.1, -1.7],
        direction: [0, 0, -1],
        range: 30,
      },
      {
        name: 'frontLeft',
        offsetPosition: [0, -0.1, -1.7],
        direction: [2, 0, -1],
        range: 50,
      },
      {
        name: 'altitude',
        offsetPosition: [0, -1.4, 0],
        direction: [0, -1, 0],
        range: -1,
      },
    ],
  },
];

const maps = [
  {
    id: 'map1',
    name: 'The River',
    difficulty: 'Easy',
    model: {
      filename: './models/map1.glb',
      get file() {
        return require('./models/map1.glb');
      },
    },
    vehicle: {
      vehicleId: 'airship',
      position: [0, 20, 0],
      rotation: [0, 0, 0],
    },
    goal: { position: [95, 20, 0], radius: 20 },
  },
  {
    id: 'map4',
    name: 'Narrow Passage',
    difficulty: 'Easy',
    model: {
      filename: './models/map4.glb',
      get file() {
        return require('./models/map4.glb');
      },
    },
    vehicle: {
      vehicleId: 'airship',
      position: [0, -5, 0],
      rotation: [0, 0, 0],
    },
    goal: { position: [110, 0, -4], radius: 20 },
  },
  {
    id: 'map2',
    name: 'Snowy Mountain',
    difficulty: 'Intermediate',
    model: {
      filename: './models/map2.glb',
      get file() {
        return require('./models/map2.glb');
      },
    },
    vehicle: {
      vehicleId: 'airship',
      position: [0, 20, 0],
      rotation: [0, 0, 0],
    },
    goal: { position: [280, 20, 0], radius: 20 },
  },
  {
    id: 'map5',
    name: 'Curved Canyon',
    difficulty: 'Intermediate',
    model: {
      filename: './models/map5.glb',
      get file() {
        return require('./models/map5.glb');
      },
    },
    vehicle: {
      vehicleId: 'airship',
      position: [0, -5, -10],
      rotation: [0, 0, 0],
    },
    goal: { position: [167, 0, 73], radius: 20 },
  },
  {
    id: 'map6',
    name: 'Casino Chicane',
    difficulty: 'Intermediate',
    model: {
      filename: './models/map6.glb',
      get file() {
        return require('./models/map6.glb');
      },
    },
    vehicle: {
      vehicleId: 'airship',
      position: [0, -5, 0],
      rotation: [0, 0.44, 0],
    },
    goal: { position: [226, 0, -2.5], radius: 20 },
  },
  {
    id: 'map7',
    name: 'Black Mesa',
    difficulty: 'Challenging',
    model: {
      filename: './models/map7.glb',
      get file() {
        return require('./models/map7.glb');
      },
    },
    vehicle: {
      vehicleId: 'airship',
      position: [0, -3, 0],
      rotation: [0, 0, 0],
    },
    goal: { position: [272, 0, 58], radius: 20 },
  },
  {
    id: 'map8',
    name: 'Firaxis Canyon',
    difficulty: 'Hard',
    model: {
      filename: './models/map8.glb',
      get file() {
        return require('./models/map8.glb');
      },
    },
    vehicle: {
      vehicleId: 'airship',
      position: [0, -8, 0],
      rotation: [0, 0, 0],
    },
    goal: { position: [153, 0, -44], radius: 20 },
  },
  {
    id: 'map3',
    name: 'Twin Peaks',
    difficulty: 'Very Hard',
    model: {
      filename: './models/map3.glb',
      get file() {
        return require('./models/map3.glb');
      },
    },
    vehicle: {
      vehicleId: 'airship',
      position: [0, 35, 0],
      rotation: [0, 0, 0],
    },
    goal: { position: [510, 35, 0], radius: 20 },
  },
];

module.exports = {
  maps,
  vehicles,
};
