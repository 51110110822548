import React from 'react';
import { Outlet } from 'react-router-dom';
import Amplify, { Hub } from 'aws-amplify';
import jwtDecode from 'jwt-decode';
import styles from './Layout.module.scss';
import Navbar from './Navbar/Navbar';
import AuthenticationContext from '../../../contexts/AuthenticationContext';
import APIClient from '../../../util/APIClient';
import Loading from '../../_view/Common/Loading/Loading';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  },
});

export default function Layout() {
  const [isAuthenticationComplete, setIsAuthenticationComplete] =
    React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [credentials, setCredentials] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    function updateCurrentAuthenticatedUser() {
      Amplify.Auth.currentAuthenticatedUser()
        .then((user) => {
          setCurrentUser(user);
          Amplify.Auth.currentSession().then((currentSession) => {
            const idToken = currentSession.getIdToken().getJwtToken();
            const userGroups = jwtDecode(idToken)['cognito:groups'];
            // TODO: Take the admin group name from configuration
            if (
              Array.isArray(userGroups) &&
              userGroups.includes('administrators')
            ) {
              setIsAdmin(true);
            }
            APIClient.defaults.headers.common.Authorization = `Bearer ${idToken}`;
            setCredentials(`Bearer ${idToken}`);
          });
        })
        .catch(() => {
          setIsAdmin(false);
          setCurrentUser(null);
          setCredentials(null);
        })
        .finally(() => {
          setIsAuthenticationComplete(true);
        });
    }

    function onAuthEvent({ payload }) {
      // eslint-disable-next-line default-case
      switch (payload.event) {
        case 'signIn':
        case 'tokenRefresh':
          updateCurrentAuthenticatedUser();
          break;
        case 'signOut':
        case 'signIn_failure':
          setIsAdmin(false);
          setCredentials(null);
          setCurrentUser(null);
          APIClient.defaults.headers.common.Authorization = undefined;
          break;
      }
    }

    Hub.listen('auth', onAuthEvent);
    updateCurrentAuthenticatedUser();
  }, []);

  const readyToRender =
    isAuthenticationComplete &&
    ((currentUser && credentials) || currentUser === null);

  return (
    <AuthenticationContext.Provider
      value={{
        isAdmin,
        credentials,
        currentUser,
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.leftColumn}>
          <Navbar />
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.view}>
            {readyToRender ? (
              <Outlet />
            ) : (
              <Loading message="Authenticating..." />
            )}
          </div>
        </div>
      </div>
    </AuthenticationContext.Provider>
  );
}
