const _ammo = require('@enable3d/ammo-on-nodejs/ammo/ammo');
const { Physics, ExtendedObject3D } = require('@enable3d/ammo-on-nodejs');
const Utilities = require('./Utilities');
const Vehicle = require('./Vehicle');

function setDebugMaterial(mesh) {
  mesh.material.color = {
    r: 255,
    g: 255,
    b: 255,
  };
  mesh.material.wireframe = true;
}

function getTransformedMesh(mesh) {
  let current = mesh;
  const meshProps = {
    position: [mesh.position.x, mesh.position.y, mesh.position.z],
    rotation: [mesh.rotation.x, mesh.rotation.y, mesh.rotation.z],
    scale: [mesh.scale.x, mesh.scale.y, mesh.scale.z],
  };
  while (current.parent && current.parent.position) {
    meshProps.position[0] += current.parent.position.x;
    meshProps.position[1] += current.parent.position.y;
    meshProps.position[2] += current.parent.position.z;
    meshProps.rotation[0] += current.parent.rotation.x;
    meshProps.rotation[1] += current.parent.rotation.y;
    meshProps.rotation[2] += current.parent.rotation.z;
    meshProps.scale[0] += current.parent.scale.x;
    meshProps.scale[1] += current.parent.scale.y;
    meshProps.scale[2] += current.parent.scale.z;
    current = current.parent;
  }

  const transformedMesh = mesh.clone();
  transformedMesh.position.set(
    meshProps.position[0],
    meshProps.position[1],
    meshProps.position[2]
  );
  transformedMesh.rotation.set(
    meshProps.rotation[0],
    meshProps.rotation[1],
    meshProps.rotation[2]
  );
  transformedMesh.scale.set(
    meshProps.scale[0],
    meshProps.scale[1],
    meshProps.scale[2]
  );
  setDebugMaterial(transformedMesh);
  return transformedMesh;
}

module.exports = class Game {
  constructor(mapConfig, vehicleConfig, mapGLTF, vehicleGLTF) {
    // BEWARE: _ammo is supposed to be async, but for whatever reason loads synchronously.
    // Possible race condition, if the ammo loading breaks, this should be the first place to look.
    this.ammo = _ammo();
    // eslint-disable-next-line no-undef
    globalThis.Ammo = this.ammo;
    this.mapConfig = mapConfig;
    this.vehicleConfig = vehicleConfig;
    this.mapGLTF = mapGLTF;
    this.vehicleGLTF = vehicleGLTF;
    this.physics = new Physics();
    this.vehicle = null;
    this.timestep = 1 / 30;
    this.goalReached = false;
    this.vehicleCrashed = false;
    this.userCodeHasThrown = false;
    this.maxSimulationDurationInS = 10 * 15;
    this.maxFrameCount = (1 / this.timestep) * this.maxSimulationDurationInS;
    this._init();
    this.frames = [this._getInitialFrame()];
  }

  _init() {
    this._initMap();
    this._initGoal();
    this._initVehicle();
    this.physics.update(1);
  }

  _initVehicle() {
    const vehicle = Utilities.getModelByNodeId(
      this.vehicleGLTF,
      this.vehicleConfig.nodeId
    );
    vehicle.traverse((child) => {
      if (child.isMesh) {
        setDebugMaterial(child);
      }
    });
    const eo3d = new ExtendedObject3D();
    eo3d.name = this.vehicleConfig.nodeId;
    eo3d.add(vehicle.clone());
    eo3d.position.set(
      this.mapConfig.vehicle.position[0],
      this.mapConfig.vehicle.position[1],
      this.mapConfig.vehicle.position[2]
    );
    eo3d.rotation.set(
      this.mapConfig.vehicle.rotation[0],
      this.mapConfig.vehicle.rotation[1],
      this.mapConfig.vehicle.rotation[2]
    );

    this.physics.add.existing(eo3d, {
      shape: 'convex',
      mass: 1,
      autoCenter: false,
    });
    eo3d.body.setGravity(0, 0, 0);
    eo3d.body.on.collision((collider, event) => {
      this._handleVehicleCollision(collider, event);
    });
    this.vehicle = new Vehicle(
      this.physics,
      eo3d,
      this.vehicleConfig,
      this.mapConfig
    );
  }

  _handleVehicleCollision(collider) {
    if (collider.name !== this.goal.name) {
      this.vehicleCrashed = true;
    }
  }

  _initMap() {
    const scene = this.mapGLTF.scenes[0];
    const wrapper = new ExtendedObject3D();
    wrapper.add(scene);

    wrapper.traverse((child) => {
      if (child.isMesh) {
        const m = getTransformedMesh(child.clone());
        const eo3d = new ExtendedObject3D();
        eo3d.name = child.name; // TODO: fix this
        eo3d.add(m);
        this.physics.add.existing(eo3d, {
          shape: 'concave',
          mass: 0,
          collisionFlags: 1,
          autoCenter: false,
        });
        eo3d.body.setAngularFactor(0, 0, 0);
        eo3d.body.setLinearFactor(0, 0, 0);
      }
    });
  }

  _initGoal() {
    const goal = this.physics.add.sphere({
      name: 'goal',
      radius: this.mapConfig.goal.radius,
      x: this.mapConfig.goal.position[0],
      y: this.mapConfig.goal.position[1],
      z: this.mapConfig.goal.position[2],
      collisionFlags: 5,
    });

    setDebugMaterial(goal);

    goal.body.on.collision((collider, event) =>
      this._handleGoalCollision(collider, event)
    );
    this.goal = goal;
  }

  _handleGoalCollision(collider) {
    if (collider.name !== this.vehicleConfig.nodeId) {
      return;
    }
    this.goalReached = true;
  }

  _isSimulationDone() {
    if (this.goalReached === true) {
      // The goal has been reached
      return true;
    }
    if (this.vehicleCrashed === true) {
      // The vehicle has crashed
      return true;
    }
    if (this.userCodeHasThrown === true) {
      // User's code has thrown an error and simulation was aborted
      return true;
    }
    if (this.frames.length >= this.maxFrameCount) {
      // Maximum number of frames already computed
      return true;
    }
    return false;
  }

  _getCurrentFrame() {
    const frame = {
      vehicle: {
        position: [
          this.vehicle.object.position.x,
          this.vehicle.object.position.y,
          this.vehicle.object.position.z,
        ],
        rotation: [
          this.vehicle.object.rotation.x,
          this.vehicle.object.rotation.y,
          this.vehicle.object.rotation.z,
        ],
        sensors: this.vehicle.sensors.map((s) => ({
          name: s.name,
          from: s.worldFrom,
          to: s.hitPosition,
          hit: s.hit,
        })),
      },
    };
    if (this.goalReached) {
      frame.goalReached = true;
    }
    return frame;
  }

  _saveVehicleFrame() {
    this.frames.push(this._getCurrentFrame());
  }

  _update() {
    try {
      if (!this._isSimulationDone()) {
        this.physics.update(this.timestep * 1000);
        this.vehicle.update();
        // this.vehicle._applyDrag();
        this._saveVehicleFrame();
      }
    } catch (error) {
      this.userCodeHasThrown = true;
      throw error;
    }
    // 'Maximum number of frames has already been simulated! Aborting!'
  }

  _getInitialFrame() {
    return this._getCurrentFrame();
  }

  getFrames() {
    return this.frames;
  }

  getScore() {
    if (!this._isSimulationDone()) {
      throw new Error('Simulation is not finished! Cannot compute score.');
    }
    if (this.userCodeHasThrown) {
      return 0;
    }
    if (this.vehicleCrashed) {
      return 0;
    }
    if (!this.goalReached) {
      return 0;
    }
    return Math.max(this.maxFrameCount - this.frames.length, 0);
  }

  simulateStep() {
    this._update();
  }

  logFrames() {
    // eslint-disable-next-line no-console
    console.log(
      'Frames:',
      this.frames.map(
        (f, i) =>
          `[${i}] - Position: (${f.vehicle.position}), Rotation: (${f.vehicle.rotation}).`
      )
    );
  }
};
