import React from 'react';
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../AccountLayout.module.scss';
import AccountFormContext from '../../../../contexts/AccountFormContext';
import UnauthenticatedRoute from '../../../_functional/UnauthenticatedRoute';

function getFormError(formData) {
  const { password, repeatPassword, email, firstName, lastName, nickname } =
    formData;

  if (password !== repeatPassword) {
    return "Passwords don't match!";
  }

  if (password.length < 8) {
    return 'Invalid password! Password needs to be at least 8 characters long.';
  }

  if (
    !(/[a-z]/.test(password) && /[A-Z]/.test(password) && /\d/.test(password))
  ) {
    return 'Invalid password! Password needs to contain at least one lower-case letter, upper-case letter and a digit.';
  }

  if (firstName.length < 1) {
    return 'First Name needs to be provided';
  }

  if (!/^[a-z-]+$/i.test(firstName)) {
    return 'First name can only contain letters and dashes.';
  }

  if (firstName.length > 20) {
    return 'First name cannot be longer than 20 characters.';
  }

  if (lastName.length < 1) {
    return 'Last name needs to be provided';
  }

  if (!/^[a-z-]+$/i.test(lastName)) {
    return 'Last name can only contain letters and dashes.';
  }

  if (lastName.length > 20) {
    return 'Last name cannot be longer than 20 characters.';
  }

  if (!/^[a-z0-9-.]+$/i.test(nickname)) {
    return 'Display name can only contain letters, numbers, dashes and dots.';
  }

  if (nickname.length < 3 || nickname.length > 30) {
    return 'Display name needs to be between 3 and 30 characters long.';
  }

  if (
    !email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return 'Invalid e-mail address.';
  }

  return null;
}

function SignUp() {
  const { setMessage, setMessageType } = React.useContext(AccountFormContext);
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [nickname, setNickname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [repeatPassword, setRepeatPassword] = React.useState('');

  async function onSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    setMessageType('');
    setMessage('');

    const formError = getFormError({
      email,
      firstName,
      lastName,
      nickname,
      password,
      repeatPassword,
    });
    if (formError) {
      setMessageType('error');
      setMessage(formError);
      setIsSubmitting(false);
      return;
    }

    const userData = {
      username: email,
      password: repeatPassword,
      attributes: {
        given_name: firstName,
        family_name: lastName,
        nickname,
      },
    };
    try {
      await Auth.signUp(userData);
      setMessage(
        'You have been signed up! Please confirm your email before logging in.'
      );
      setMessageType('success');
      navigate('/account/signIn');
    } catch (error) {
      setMessageType('error');
      setMessage('Sign-up has failed! Please contact support.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <h1>Sign up</h1>
      <p>
        Make sure that your password is at least 8 characters long, and contains
        at least one capital letter, one lowercase letter and a number.
      </p>
      <form onSubmit={onSubmit}>
        <label htmlFor="nickname">
          Display name:
          <input
            type="text"
            name="nickname"
            autoComplete="nickname"
            value={nickname}
            onChange={(e) => {
              e.preventDefault();
              setNickname(e.target.value);
            }}
          />
        </label>
        <label htmlFor="email">
          Email address:
          <input
            type="email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => {
              e.preventDefault();
              setEmail(e.target.value.toLowerCase());
            }}
          />
        </label>
        <label htmlFor="firstName">
          First name:
          <input
            type="text"
            name="firstName"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) => {
              e.preventDefault();
              setFirstName(e.target.value);
            }}
          />
        </label>
        <label htmlFor="lastName">
          Last name:
          <input
            type="text"
            name="lastName"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) => {
              e.preventDefault();
              setLastName(e.target.value);
            }}
          />
        </label>
        <label htmlFor="password">
          New password:
          <input
            type="password"
            name="password"
            autoComplete="off"
            value={password}
            onChange={(e) => {
              e.preventDefault();
              setPassword(e.target.value);
            }}
          />
        </label>
        <label htmlFor="repeatPassword">
          Repeat new password:
          <input
            type="password"
            name="repeatPassword"
            autoComplete="off"
            value={repeatPassword}
            onChange={(e) => {
              e.preventDefault();
              setRepeatPassword(e.target.value);
            }}
          />
        </label>
        <input type="submit" value="Sign Up" disabled={isSubmitting} />
      </form>
      <p className={styles.formText}>
        Already have an account? <Link to="/account/signIn">Sign in</Link>{' '}
        instead.
      </p>
    </>
  );
}

export default UnauthenticatedRoute(SignUp);
