import React from 'react';
import styles from './AdminLeaderboardEntry.module.scss';

export default function AdminLeaderboardEntry(props) {
  const {
    map: { name, submissions },
  } = props;

  return (
    <div className={styles.leaderboardEntry}>
      <div className={styles.leaderboardEntryHeader}>
        <p className={styles.mapName}>{name}</p>
      </div>
      <div className={styles.leaderboardEntrySubmissions}>
        {Array.isArray(submissions) &&
          submissions.length > 0 &&
          submissions
            .sort((a, b) => b.score - a.score)
            .map((submission) => (
              <div className={styles.submission} key={`${submission.id}`}>
                <p className={styles.user}>
                  {submission.displayName}
                  <span className={styles.sourceCode}>
                    ({submission.sourceCodeName})
                  </span>
                </p>
                <p className={styles.score}>{submission.status}</p>
              </div>
            ))}
        {Array.isArray(submissions) && submissions.length === 0 && (
          <p className={styles.noSubmissions}>
            This map does not have any submissions yet!
          </p>
        )}
      </div>
    </div>
  );
}
