import React from 'react';
import { Amplify } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
// import LeaderboardIcon from '@mui/icons-material/Leaderboard';
// import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
// import MenuBookIcon from '@mui/icons-material/MenuBook';
// import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import NavbarLogo from './NavbarLogo/NavbarLogo';
import NavbarItem from './NavbarItem/NavbarItem';
import NavbarItemProfile from './NavbarItemProfile/NavbarItemProfile';
import NavbarItemDocumentation from './NavbarItemDocumentation/NavbarItemDocumentation';
import styles from './Navbar.module.scss';
import AuthenticationContext from '../../../../contexts/AuthenticationContext';

export default function Navbar() {
  const [expanded, setExpanded] = React.useState(true);
  const { credentials, isAdmin } = React.useContext(AuthenticationContext);
  const navigate = useNavigate();

  const handleToggleClick = () => {
    setExpanded((current) => !current);
  };

  const signOut = () => {
    Amplify.Auth.signOut().then(() => navigate('/'));
  };

  return (
    <div className={styles.navbar}>
      <NavbarLogo handleToggleClick={handleToggleClick} isExpanded={expanded} />
      <div className={styles.itemsWrapper}>
        <NavbarItemDocumentation isExpanded={expanded} path="/documentation" />
        {/* <NavbarItem */}
        {/* label="Leaderboards" */}
        {/* icons={{ */}
        {/*   active: <LeaderboardIcon fontSize="inherit" htmlColor="#b01c2e" />, */}
        {/*   inactive: ( */}
        {/*     <LeaderboardOutlinedIcon fontSize="inherit" htmlColor="#999999" /> */}
        {/*   ), */}
        {/* }} */}
        {/* path="/leaderboards" */}
        {/* isExpanded={expanded} */}
        {/* /> */}
        {credentials && (
          <>
            <NavbarItem
              label="Maps"
              icons={{
                active: <MapIcon fontSize="inherit" htmlColor="#b01c2e" />,
                inactive: (
                  <MapOutlinedIcon fontSize="inherit" htmlColor="#999999" />
                ),
              }}
              path="/maps"
              isExpanded={expanded}
            />
            <NavbarItem
              label="My Code"
              icons={{
                active: (
                  <IntegrationInstructionsIcon
                    fontSize="inherit"
                    htmlColor="#b01c2e"
                  />
                ),
                inactive: (
                  <IntegrationInstructionsOutlinedIcon
                    fontSize="inherit"
                    htmlColor="#999999"
                  />
                ),
              }}
              path="/sourceCode"
              isExpanded={expanded}
            />
            <NavbarItem
              label="My Games"
              icons={{
                active: (
                  <EmojiEventsIcon fontSize="inherit" htmlColor="#b01c2e" />
                ),
                inactive: (
                  <EmojiEventsOutlinedIcon
                    fontSize="inherit"
                    htmlColor="#999999"
                  />
                ),
              }}
              path="/games"
              isExpanded={expanded}
            />
          </>
        )}
        <div className={styles.fillMax} />
        {(credentials && (
          <>
            {isAdmin && (
              <NavbarItem
                label="Admin"
                icons={{
                  active: (
                    <LocalPoliceIcon fontSize="inherit" htmlColor="#b01c2e" />
                  ),
                  inactive: (
                    <LocalPoliceOutlinedIcon
                      fontSize="inherit"
                      htmlColor="#999999"
                    />
                  ),
                }}
                path="/admin"
                isExpanded={expanded}
              />
            )}
            <NavbarItemProfile path="/account/profile" isExpanded={expanded} />
            <NavbarItem
              label="Sign out"
              icons={{
                inactive: (
                  <LogoutOutlinedIcon fontSize="inherit" htmlColor="#999999" />
                ),
              }}
              onClick={signOut}
              isExpanded={expanded}
            />
          </>
        )) || (
          <NavbarItem
            label="Sign in"
            icons={{
              active: <LoginOutlinedIcon htmlColor="#b01c2e" />,
              inactive: <LoginOutlinedIcon htmlColor="#999999" />,
            }}
            path="/account/signIn"
            isExpanded={expanded}
          />
        )}
      </div>
    </div>
  );
}
