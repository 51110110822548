import React from 'react';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import { Link } from 'react-router-dom';
import styles from './AdminGameListEntry.module.scss';
import APIClient from '../../../../../util/APIClient';

export default function GameListEntry(props) {
  const { id, name, isOpen, reload } = props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  async function toggleSubmissionStatus(e) {
    e.preventDefault();
    setIsSubmitting(true);
    const newState = !isOpen;
    try {
      await APIClient.patch(`/administrator/games/${id}`, {
        isOpen: newState,
      });
      reload();
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(
        `Could not ${newState ? 'open' : 'close'} game. Please contact support.`
      );
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className={styles.gameListEntry}>
      <div className={styles.gameHeader}>
        <p className={styles.name}>{name}</p>
        <div className={styles.horizontalFill} />
        <span className={styles.isOpen}>
          {isOpen ? 'open for submissions and joining' : 'closed'}
        </span>
        <button
          type="button"
          disabled={isSubmitting}
          onClick={toggleSubmissionStatus}
        >
          {(isOpen && (
            <LockOutlinedIcon htmlColor="#999999" fontSize="inherit" />
          )) || <LockOpenOutlinedIcon htmlColor="#999999" fontSize="inherit" />}
        </button>
        <Link className={styles.link} to={`/admin/leaderboards?gameId=${id}`}>
          <LeaderboardIcon htmlColor="#999999" fontSize="inherit" />
        </Link>
        <Link className={styles.link} to={`/admin/games/${id}/shareJoinCode`}>
          <ScreenShareOutlinedIcon htmlColor="#999999" fontSize="inherit" />
        </Link>
      </div>
    </div>
  );
}
