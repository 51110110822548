import React from 'react';
import { Auth } from 'aws-amplify';
import { useSearchParams, useNavigate } from 'react-router-dom';
import UnauthenticatedRoute from '../../../_functional/UnauthenticatedRoute';
import AccountFormContext from '../../../../contexts/AccountFormContext';

function VerifyEmailAutomatic() {
  const { setMessage, setMessageType } = React.useContext(AccountFormContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    Auth.confirmSignUp(searchParams.get('email'), searchParams.get('code'))
      .then(() => {
        setMessage('Email address verified successfully! You can now sign-in.');
        setMessageType('success');
      })
      .catch(() => {
        setMessageType('error');
        setMessage('Failed to verify email address. Please contact support.');
      })
      .finally(() => {
        navigate('/account/signIn');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // }, [setError, setMessage, navigate, searchParams]);

  return <></>;
}

export default UnauthenticatedRoute(VerifyEmailAutomatic);
