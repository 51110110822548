import React from 'react';
import { Auth } from 'aws-amplify';
import { useSearchParams, useNavigate } from 'react-router-dom';
import AccountFormContext from '../../../../contexts/AccountFormContext';

function CompleteEmailChangeAutomatic() {
  const { setMessage, setMessageType } = React.useContext(AccountFormContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    Auth.verifyUserAttributeSubmit(
      searchParams.get('email'),
      'email',
      searchParams.get('code')
    )
      .then(() => {
        setMessage('New email address verified successfully!');
        setMessageType('success');
      })
      .catch(() => {
        setMessageType('error');
        setMessage(
          'New email address could not be verified. Please contact support.'
        );
      })
      .finally(() => {
        navigate('/account/signIn');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}

export default CompleteEmailChangeAutomatic;
