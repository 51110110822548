/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import styles from '../Documentation.module.scss';

export default function APIReference() {
  return (
    <>
      <h1>API Reference</h1>
      <div className={styles.section}>
        <p>
          You can control your vehicle primarily by setting its throttle
          (otherwise known as the propeller speed) and rudder (that's the
          control surface at the back of the airship that turns if left and
          right). In addition to that, you have a wide range of telemetry, or
          readings about the vehicle status, such as its altitude, GPS position,
          and proximity sensor readings. The below sections will explain how you
          can access and use both the control commands and the telemetry of the
          airship.
        </p>
      </div>

      <div className={styles.section}>
        <h2>Controlling your vehicle</h2>
        <p>
          Your vehicle can go backwards and forwards. It can also turn left and
          right. It's relatively simple! You can control the speed at which you
          move forward (or back) using the{' '}
          <span className={styles.code}>setThrottle</span> function, and the
          rotation of your airship with{' '}
          <span className={styles.code}>setRudder</span> function.
        </p>

        <p>
          To direct your vehicle towards the goal, you will have to use both the
          knowledge of the map, and the access to a variety of vehicle sensors.
          You can approach this task by first looking at the map you wish to
          complete, and then thinking of a strategy to navigate through it.
        </p>
        <br />
        <p>
          There are three main approaches to directing your airship. You can
          either design a path manually, by specifying when to set the rudder
          left, or right depending on the{' '}
          <span className={styles.code}>gps</span> coordinates and the on-board{' '}
          <span className={styles.code}>compass</span>. If this seems like a
          challenging process, don't worry! There are smarter way of providing
          directions to your airship. A good approach would be to make use of
          vehicle's proximity <span className={styles.code}>sensors</span>,
          which will inform you about the distance to the obstacles in the
          direction of each sensor.
        </p>
        <br />
        <p>
          Alternatively, you could combine both approaches and guide the airship
          through the narrowest of passages by allowing it to rely on sensors
          between given sets of coordinates, and forcing it to fly a certain
          compass heading between another.
        </p>
      </div>

      <div className={styles.section}>
        <h2>Vehicle controls</h2>
        <p>
          You can control how your airship moves by simply issuing the two
          control commands with various arguments.
        </p>
        <div className={styles.apiDoc}>
          <h3 className={styles.apiElement}>setThrottle(value: Number)</h3>
          <p>
            Sets the propeller speed of the airship to go forward and back.
            <p className={styles.apiSection}>Arguments:</p>
            <span className={styles.apiProperty}>value</span> - the direction
            and magnitude of the propeller rotation. Setting this value to -1
            will 'pull' the airship backwards, and setting it to 1 will 'push'
            the airship forwards. By setting the values in-between, the vehicle
            will move slower in either direction.
            <p className={styles.apiSection}>Returns:</p>
            <p className={styles.nothing}>Nothing</p>
          </p>
        </div>
        <div className={styles.apiDoc}>
          <h3 className={styles.apiElement}>setRudder(value: Number)</h3>
          <p>
            Sets the rudder of the airship to turn left or right.
            <p className={styles.apiSection}>Arguments:</p>
            <span className={styles.apiProperty}>value</span> - the direction
            and magnitude of the rudder deviation. Setting this value to 1 will
            turn the airship left, and setting it to -1 will turn it to the
            right. By setting the values in-between, the vehicle will turn
            slower in either direction.
            <p className={styles.apiSection}>Returns:</p>
            <p className={styles.nothing}>Nothing</p>
          </p>
        </div>
      </div>

      <div className={styles.section}>
        <h2>Vehicle telemetry</h2>
        <p>
          You can access a variety of different sensors that will help you in
          navigating through the maps. By combining these with the control
          functions - both <span className={styles.code}>setThrottle</span> and{' '}
          <span className={styles.code}>setRudder</span>, you will be able to
          write simple programs that will guide your airship using the map
          features.
        </p>
        <div className={styles.apiDoc}>
          <h3 className={styles.apiElement}>altitude</h3>
          <p>
            Returns the above-sea-level and above-ground altitude of the
            airship.
            <p className={styles.apiSection}>Arguments:</p>
            <span className={styles.nothing}>None</span>
            <p className={styles.apiSection}>Returns:</p>
            <span className={styles.apiProperty}>&#123;</span>
            <p>
              <p>
                <span className={styles.apiProperty}>seaLevel: Number</span> -
                the above-sea-level altitude of the airship.
                <br />
                <span className={styles.apiProperty}>ground: Number</span> - the
                above-ground altitude of the airship.
              </p>
            </p>
            <span className={styles.apiProperty}>&#125;</span>
          </p>
        </div>
        <div className={styles.apiDoc}>
          <h3 className={styles.apiElement}>compass</h3>
          <p>
            Returns the compass heading of the airship.
            <p className={styles.apiSection}>Arguments:</p>
            <span className={styles.nothing}>None</span>
            <p className={styles.apiSection}>Returns:</p>
            <span className={styles.apiProperty}>heading: Number</span> -
            heading of the airship. The value of 180 corresponds to facing the
            x-axis directly, and the value of 90 corresponds to facing z-axis
            directly.
          </p>
        </div>
        <div className={styles.apiDoc}>
          <h3 className={styles.apiElement}>speed</h3>
          <p>
            Returns the true speed of the airship.
            <p className={styles.apiSection}>Arguments:</p>
            <span className={styles.nothing}>None</span>
            <p className={styles.apiSection}>Returns:</p>
            <span className={styles.apiProperty}>speed: Number</span> - the
            speed of the airship.
          </p>
        </div>
        <div className={styles.apiDoc}>
          <h3 className={styles.apiElement}>velocity</h3>
          <p>
            Returns the velocity of the airship. As opposed to{' '}
            <span className={styles.code}>speed</span>, which is a scalar value,
            velocity is a vector and contains directional information about how
            fast the airship is moving.
            <p className={styles.apiSection}>Arguments:</p>
            <span className={styles.nothing}>None</span>
            <p className={styles.apiSection}>Returns:</p>
            <span className={styles.apiProperty}>&#123;</span>
            <p>
              <p>
                <span className={styles.apiProperty}>x: Number</span> - the x
                component of velocity, or how fast the ship is moving alongside
                x-axis.
                <br />
                <span className={styles.apiProperty}>y: Number</span> - the y
                component of velocity, or how fast the ship is moving alongside
                y-axis.
                <br />
                <span className={styles.apiProperty}>z: Number</span> - the z
                component of velocity, or how fast the ship is moving alongside
                z-axis.
              </p>
            </p>
            <span className={styles.apiProperty}>&#125;</span>
          </p>
        </div>
        <div className={styles.apiDoc}>
          <h3 className={styles.apiElement}>direction</h3>
          <p>
            Returns the direction, which the airship is facing. As opposed to{' '}
            <span className={styles.code}>velocity</span>, direction is the
            vector equivalent of the{' '}
            <span className={styles.code}>compass</span> heading. The airship
            does not necessarily move in the direction it is facing, due to its
            drag and angular velocity.
            <p className={styles.apiSection}>Arguments:</p>
            <span className={styles.nothing}>None</span>
            <p className={styles.apiSection}>Returns:</p>
            <span className={styles.apiProperty}>&#123;</span>
            <p>
              <p>
                <span className={styles.apiProperty}>x: Number</span> - the x
                component of direction.
                <br />
                <span className={styles.apiProperty}>y: Number</span> - the y
                component of direction.
                <br />
                <span className={styles.apiProperty}>z: Number</span> - the z
                component of direction.
              </p>
            </p>
            <span className={styles.apiProperty}>&#125;</span>
          </p>
        </div>
        <div className={styles.apiDoc}>
          <h3 className={styles.apiElement}>drag</h3>
          <p>
            Returns the direction of drag that the airship is experiencing.
            <p className={styles.apiSection}>Arguments:</p>
            <span className={styles.nothing}>None</span>
            <p className={styles.apiSection}>Returns:</p>
            <span className={styles.apiProperty}>&#123;</span>
            <p>
              <p>
                <span className={styles.apiProperty}>x: Number</span> - the x
                component of the drag.
                <br />
                <span className={styles.apiProperty}>y: Number</span> - the y
                component of the drag.
                <br />
                <span className={styles.apiProperty}>z: Number</span> - the z
                component of the drag.
              </p>
            </p>
            <span className={styles.apiProperty}>&#125;</span>
          </p>
        </div>
        <div className={styles.apiDoc}>
          <h3 className={styles.apiElement}>sensors</h3>
          <p>
            Returns the array of the proximity sensors that the airship is
            equipped with.
            <p className={styles.apiSection}>Arguments:</p>
            <span className={styles.nothing}>None</span>
            <p className={styles.apiSection}>Returns:</p>
            <span className={styles.apiProperty}>&#91;</span>
            <p>
              <p>
                <span className={styles.apiProperty}>&#123;</span>
                <p>
                  <p>
                    <span className={styles.apiProperty}>name: String</span> -
                    the name of the sensor.
                    <br />
                    <span className={styles.apiProperty}>hit: Boolean</span> -
                    whether the sensor has detected an obstacle within its
                    range. This value will be{' '}
                    <span className={styles.code}>true</span> if the sensor has
                    encountered an obstacle, or{' '}
                    <span className={styles.code}>false</span> if there was not
                    anything within its range.
                    <br />
                    <span className={styles.apiProperty}>
                      distance: Number
                    </span>{' '}
                    - the distance to the closest obstacle. This value will be
                    equivalent to the sensor's maximum ranging distance if there
                    was not an obstacle within range.
                  </p>
                </p>
                <span className={styles.apiProperty}>&#125; :Sensor</span>
              </p>
            </p>
            <span className={styles.apiProperty}>&#93;</span>
          </p>
        </div>
      </div>
    </>
  );
}

// gps: this._getGps(),
//   altitude: this._getAltitude(),
//   compass: this._getCompass(),
//   speed: this._getSpeed(),
//   velocity: {
//   x: this.velocity.x,
//     y: this.velocity.y,
//     z: this.velocity.z,
// },
// direction: {
//   x: this.direction.x,
//     y: this.direction.y,
//     z: this.direction.z,
// },
// drag: {
//   x: this.drag.x,
//     y: this.drag.y,
//     z: this.drag.z,
// },
// sensors: this.sensors.map((s) => ({
//   name: s.name,
//   hit: s.hit,
//   distance: s.distance,
// })),
