import React from 'react';
import { Link } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import APIClient from '../../../../util/APIClient';
import styles from './SourceCodeList.module.scss';
import SourceCodeListEntry from './SourceCodeListEntry/SourceCodeListEntry';
import Loading from '../../Common/Loading/Loading';

export default function SourceCodeList() {
  const [sourceCodeEntries, setSourceCodeEntries] = React.useState(null);
  const [isLoadingSourceCode, setIsLoadingSourceCode] = React.useState(true);
  const [maps, setMaps] = React.useState(null);
  const [isLoadingMaps, setIsLoadingMaps] = React.useState(true);

  const reload = React.useCallback(() => {
    APIClient.get(
      '/participant/sourceCode?includeSubmissions=true&includeSubmissionGames=true'
    )
      .then((result) => {
        setSourceCodeEntries(result.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    APIClient.get(
      '/participant/sourceCode?includeSubmissions=true&includeSubmissionGames=true'
    )
      .then((result) => {
        setSourceCodeEntries(result.data);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Failed to load source code!');
      })
      .finally(() => {
        setIsLoadingSourceCode(false);
      });
    APIClient.get('/participant/maps')
      .then((result) => {
        setMaps(result.data);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Failed to load maps!');
      })
      .finally(() => {
        setIsLoadingMaps(false);
      });
  }, []);

  if (isLoadingSourceCode || isLoadingMaps) {
    return <Loading />;
  }

  if (sourceCodeEntries === null || maps === null) {
    return (
      <p className={styles.errorBanner}>
        Failed to load code entries and maps.
      </p>
    );
  }

  return (
    <div className={styles.sourceCodeList}>
      <div className={styles.header}>
        <h1 className={styles.title}>Your source code</h1>
        <div className={styles.horizontalFill} />
        <Link className={styles.newSourceCode} to="/maps">
          Create
        </Link>
        <button type="button" onClick={reload}>
          <RefreshIcon htmlColor="#999999" fontSize="inherit" />
        </button>
      </div>
      {sourceCodeEntries.map((sourceCode) => (
        <SourceCodeListEntry
          key={sourceCode.id}
          id={sourceCode.id}
          name={sourceCode.name}
          code={sourceCode.code}
          compiledCode={sourceCode.compiledCode}
          submissions={sourceCode.submissions || []}
          maps={maps}
          reload={reload}
        />
      ))}
      {sourceCodeEntries.length === 0 && (
        <div className={styles.noSourceCode}>
          <p>You do not have any code entries yet.</p>
        </div>
      )}
    </div>
  );
}
