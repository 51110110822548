import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import AdminGameList from './AdminGameList/AdminGameList';
import AdminCreateGame from './AdminCreateGame/AdminCreateGame';
import AuthenticatedAdminRoute from '../../_functional/AuthenticatedAdminRoute';
import AdminShareJoinCode from './AdminShareJoinCode/AdminShareJoinCode';
import AdminLeaderboards from './AdminLeaderboards/AdminLeaderboards';

function AdminLayout() {
  const location = useLocation();
  return (
    <Routes>
      <Route
        index
        element={<Navigate to="games" replace state={{ location }} />}
      />
      <Route path="games" element={<AdminGameList />} />
      <Route path="games/create" element={<AdminCreateGame />} />
      <Route
        path="games/:gameId/shareJoinCode"
        element={<AdminShareJoinCode />}
      />
      <Route path="leaderboards" element={<AdminLeaderboards />} />
    </Routes>
  );
}

export default AuthenticatedAdminRoute(AdminLayout);
