import React from 'react';
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import AccountFormContext from '../../../../contexts/AccountFormContext';
import UnauthenticatedRoute from '../../../_functional/UnauthenticatedRoute';
import styles from '../AccountLayout.module.scss';

function ForgotPassword() {
  const { setMessage, setMessageType } = React.useContext(AccountFormContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const navigate = useNavigate();

  async function onSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await Auth.forgotPassword(username);
      setMessageType('success');
      setMessage(
        'Your password reset link has been sent. Please check your email.'
      );
      navigate('/account/signIn');
    } catch (error) {
      setMessageType('error');
      setMessage('The password could not be reset.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <h1>Reset password</h1>
      <form onSubmit={onSubmit}>
        <label htmlFor="username">
          Email address:
          <input
            type="email"
            name="username"
            autoComplete="username"
            value={username}
            onChange={(e) => {
              e.preventDefault();
              setUsername(e.target.value);
            }}
          />
        </label>
        <input type="submit" value="Reset password" disabled={isSubmitting} />
      </form>
      <p className={styles.formText}>
        Or <Link to="/account/signIn">sign in</Link> to existing account.
      </p>
    </>
  );
}

export default UnauthenticatedRoute(ForgotPassword);
