import React from 'react';
import { Navigate, Route, Routes, useLocation, Outlet } from 'react-router-dom';
import styles from './Documentation.module.scss';
import GettingStarted from './GettingStarted/GettingStarted';
import JavascriptBasics from './JavascriptBasics/JavascriptBasics';
import APIReference from './APIReference/APIReference';
import UserGuide from './UserGuide/UserGuide';

function DocumentationWrapper() {
  return (
    <div className={styles.documentation}>
      <Outlet />
    </div>
  );
}

function DocumentationLayout() {
  const location = useLocation();
  return (
    <Routes>
      <Route element={<DocumentationWrapper />}>
        <Route
          index
          element={
            <Navigate to="gettingStarted" replace state={{ location }} />
          }
        />
        <Route path="gettingStarted" element={<GettingStarted />} />
        <Route path="userGuide" element={<UserGuide />} />
        <Route path="javascriptBasics" element={<JavascriptBasics />} />
        <Route path="apiReference" element={<APIReference />} />
      </Route>
    </Routes>
  );
}

export default DocumentationLayout;
