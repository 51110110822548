import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SourceCode from './SourceCode/SourceCode';
import SourceCodeList from './SourceCodeList/SourceCodeList';
import AuthenticatedRoute from '../../_functional/AuthenticatedRoute';
import SimulatorProvider from './SourceCode/SimulatorProvider';

function SourceCodeWrapper() {
  return (
    <SimulatorProvider>
      <SourceCode />
    </SimulatorProvider>
  );
}

function SourceCodeLayout() {
  return (
    <Routes>
      <Route index element={<SourceCodeList />} />
      <Route path=":sourceCodeId" element={<SourceCodeWrapper />} />
    </Routes>
  );
}

export default AuthenticatedRoute(SourceCodeLayout);
