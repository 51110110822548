import React from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountFormContext from '../../../../contexts/AccountFormContext';
import AuthenticatedRoute from '../../../_functional/AuthenticatedRoute';
import APIClient from '../../../../util/APIClient';
import AuthenticationContext from '../../../../contexts/AuthenticationContext';
import styles from './Profile.module.scss';

function getFormError(formData) {
  const { firstName, lastName, nickname } = formData;

  if (firstName.length < 1) {
    return 'First Name needs to be provided';
  }

  if (!/^[a-z-]+$/i.test(firstName)) {
    return 'First name can only contain letters and dashes.';
  }

  if (firstName.length > 20) {
    return 'First name cannot be longer than 20 characters.';
  }

  if (lastName.length < 1) {
    return 'Last name needs to be provided';
  }

  if (!/^[a-z-]+$/i.test(lastName)) {
    return 'Last name can only contain letters and dashes.';
  }

  if (lastName.length > 20) {
    return 'Last name cannot be longer than 20 characters.';
  }

  if (!/^[a-z0-9-.]+$/i.test(nickname)) {
    return 'Display name can only contain letters, numbers, dashes and dots.';
  }

  if (nickname.length < 3 || nickname.length > 30) {
    return 'Display name needs to be between 3 and 30 characters long.';
  }

  return null;
}
function Profile() {
  const { setMessage, setMessageType } = React.useContext(AccountFormContext);
  const { currentUser } = React.useContext(AuthenticationContext);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [firstName, setFirstName] = React.useState(
    currentUser.attributes.given_name
  );
  const [lastName, setLastName] = React.useState(
    currentUser.attributes.family_name
  );
  const [nickname, setNickname] = React.useState(
    currentUser.attributes.nickname
  );

  async function onSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    setMessageType('');
    setMessage('');

    const formError = getFormError({ firstName, lastName, nickname });

    if (formError) {
      setMessageType('error');
      setMessage(formError);
      setIsSubmitting(false);
      return;
    }

    const userAttributes = {
      given_name: firstName,
      family_name: lastName,
      nickname,
    };
    try {
      await Auth.updateUserAttributes(currentUser, userAttributes);
      if (currentUser.attributes.nickname !== nickname) {
        await APIClient.patch('/participant/users', { displayName: nickname });
      }
      setMessageType('success');
      setMessage('Profile was updated successfully.');
    } catch (error) {
      setMessageType('error');
      setMessage('Could not update the profile.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <h1>Profile</h1>

      <form onSubmit={onSubmit}>
        <label htmlFor="email">
          Email address:
          <input
            type="email"
            name="email"
            autoComplete="email"
            value={currentUser.attributes.email}
            disabled // TODO: Fix issue #33 first
            // onChange={(e) => {
            //   e.preventDefault();
            //   setEmail(e.target.value);
            // }}
          />
        </label>
        <label htmlFor="nickname">
          Display name:
          <input
            type="text"
            name="nickname"
            value={nickname}
            onChange={(e) => {
              e.preventDefault();
              setNickname(e.target.value);
            }}
          />
        </label>
        <label htmlFor="firstName">
          First name:
          <input
            type="text"
            name="firstName"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) => {
              e.preventDefault();
              setFirstName(e.target.value);
            }}
          />
        </label>
        <label htmlFor="lastName">
          Last name:
          <input
            type="text"
            name="lastName"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) => {
              e.preventDefault();
              setLastName(e.target.value);
            }}
          />
        </label>

        <div className={styles.profileLinks}>
          <LockOutlinedIcon
            htmlColor="#b01c2e"
            fontSize="inherit"
            className={styles.icon}
          />
          <Link to="/account/changePassword">Change password</Link>
        </div>
        <input type="submit" value="Save changes" disabled={isSubmitting} />
      </form>
    </>
  );
}

export default AuthenticatedRoute(Profile);
